// @flow strict
import * as React from "react";
import LogoUrl from "../../img/concertify-logo-stream.svg";

import { Link } from "react-router-dom";

type LogoProps = {
  /** Size of the logo */
  size: "small" | "medium" | "large"
};

/** Component representing the Concertify logo */
export const Logo = (props: LogoProps) => (
    <img id="concertifyLogo" src={LogoUrl} alt="Concertify logo" {...props} />
);

Logo.defaultProps = {
  size: 'small'
}

export default (props: LogoProps) => <Link to='/'>
  <Logo {...props}/>
</Link>
