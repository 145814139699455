import React from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import urljoin from "url-join";
import {
  Authenticator,
  SignUp,
  Greetings,
  AuthPiece,
  ForgotPassword,
  VerifyContact
} from "aws-amplify-react";

import PerformancesView from "components/views/PerformancesView";
import PerformanceDetailsView from "components/views/PerformanceDetailsView";
import CreatePerformanceView from "components/views/CreatePerformanceView";
import TermsAndConditionsView from "components/views/TermsAndConditionsView";
import { PERFORMANCES_PATH } from "concertify/constants";
import TermsAndConditionsFooter from "components/TermsAndConditionsFooter";

// this is not a very robust regex for UUIds since it would
// match any endpoint that's exactly 36 chars in length
const PERFORMANCE_DETAILS_MATCH_PATH = "/performances/:id([\\w\\d\\-]{36})";
const CREATE_PERFORMANCE_ENDPOINT = urljoin(PERFORMANCES_PATH, "new");

class App extends AuthPiece {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signedIn", "verifyContact"];
    this.state = {};
  }

  showComponent(theme) {
    return (
      <div>
        <Route
          exact={true}
          path="/"
          render={() => <Redirect to={PERFORMANCES_PATH} />}
        />
        <Route
          exact={true}
          path={PERFORMANCES_PATH}
          component={PerformancesView}
        />
        <Route
          exact={true}
          path={CREATE_PERFORMANCE_ENDPOINT}
          component={CreatePerformanceView}
        />
        <Route
          exact={true}
          path={PERFORMANCE_DETAILS_MATCH_PATH}
          component={({ match }) => (
            <PerformanceDetailsView performanceId={match.params.id} />
          )}
        />
      </div>
    );
  }
}

const ConcertifyTheme = {
  container: { backgroundColor: "#2E2E64", minHeight: "100vh" },
  formContainer: {
    margin: 0,
    paddingTop: "15%"
  },
  sectionHeader: {
    color: "#fff"
  },
  inputLabel: {
    color: "#fff"
  },
  formSection: {
    background: "transparent",
    boxShadow: "none"
  },
  sectionFooter: {
    display: "block"
  },
  button: {
    background: "#8100FF",
    minWidth: 380
  },
  toast: {
    backgroundColor: "#EB4A41"
  }
};

const AppWithAuth = props => (
  <Switch>
    <Route
      exact
      path={"/terms-and-conditions"}
      component={TermsAndConditionsView}
    />
    <Authenticator
      hide={[SignUp, Greetings, ForgotPassword, VerifyContact]}
      theme={ConcertifyTheme}
    >
      <App />
      <TermsAndConditionsFooter />
    </Authenticator>
  </Switch>
);

export default AppWithAuth;
