import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Auth from "@aws-amplify/auth";

import { PERFORMANCES_PATH } from "concertify/constants";
import NavigationBar from "./NavigationBar";
import NavigationLink from "./NavigationLink";

const PARNavigationBar = props => {
  const { classes, name } = props;
  return (
    <NavigationBar>
      <NavigationLink to={PERFORMANCES_PATH}>Performances</NavigationLink>
      {name && (
        <Button id="displayName" variant="contained" className={classes.button}>
          {name}
        </Button>
      )}

      <Button
        id="signOutButton"
        onClick={e => Auth.signOut()}
        variant="contained"
        className={classes.button}
      >
        Sign out
      </Button>
    </NavigationBar>
  );
};
const styles = {
  button: {
    color: "#fff",
    marginLeft: 20,
    borderRadius: 0,
    background: "#282850",
    "&:hover": {
      background: "#8006FF"
    }
  }
};

export default withStyles(styles)(PARNavigationBar);
