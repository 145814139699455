import { Logger } from "@aws-amplify/core";

const log = new Logger("config");

// ----------------- DANGER ---------------- //
// this line should always be commited as 'dev'
const ENV = "dev";
// ------------- //

const API_VERSION = "v1";

const DYNAMODB_TABLE_NAME = "ConcertifyTable";

const config = {
  app: {
    env: ENV,
    apiVersion: API_VERSION
  },
  cognito: {
    dev: {
      region: "eu-central-1",
      userPoolId: "eu-central-1_PlRYSyZHp",
      clientId: "49eps375ag59mtgt9ghjdiqu9i",
      identityPoolId: "eu-central-1:5fe8288a-0037-4b96-a1c5-d24c0a474ebd"
    },
    prod: {
      region: "eu-central-1",
      userPoolId: "eu-central-1_PPlgck5IC",
      clientId: "7ve3ftgvhp8d1mi1bafn7uvapk",
      identityPoolId: "eu-central-1:5fe8288a-0037-4b96-a1c5-d24c0a474ebd"
    }
  },
  googleMaps: {
    apiKey: process.env.REACT_APP_GCP_MAPS_API_KEY
  },
  dynamodb: {
    tableName: `${DYNAMODB_TABLE_NAME}-${ENV}`
  },
  api: {
    url: {
      dev: "https://tmq5bj2kb1.execute-api.eu-central-1.amazonaws.com/dev/",
      prod: "https://api.concertify.io"
    },
    version: "v1"
  }
};

log.debug(config);

export default config;
