import {
  CREATE_PERFORMANCE,
  SAVE_SETLIST_SUCCESS,
  LOAD_PERFORMANCE_REQUEST,
  LOAD_PERFORMANCE_SUCCESS,
  UPDATE_PERFORMANCE,
  ADD_WORK,
  REMOVE_WORK,
  CLEAR_SETLIST,
  UPDATE_PERFORMANCE_SUCCESS
} from "../../actions/types";
import { UPDATE_SETLIST_WORK_DURATION } from "redux/actions/updateSetlistWorkDuration";

const initialState = {
  currentPerformanceDetails: {},
  newPerformance: undefined,
  setlistHasChanged: false,
  setlistHasBeenCleared: false,
  isFetching: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_PERFORMANCE:
      return {
        ...state,
        newPerformance: action.payload
      };
    case SAVE_SETLIST_SUCCESS:
      state.currentPerformanceDetails.Setlist = state.currentPerformanceDetails
        .Setlist || { Works: null };
      state.currentPerformanceDetails.Setlist.Works = action.payload.Works;
      state.currentPerformanceDetails.Setlist.Status = action.payload.Status;
      return state;
    case LOAD_PERFORMANCE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case LOAD_PERFORMANCE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentPerformanceDetails: action.payload
      };
    case UPDATE_PERFORMANCE:
      const newState = {
        ...state,
        currentPerformanceDetails: {
          ...state.currentPerformanceDetails,
          ...action.payload
        }
      };
      return newState;
    case ADD_WORK:
      // this is ugly and hardly readable but the problem is that we have deeply-nested states,
      // which is discouraged in redux. We need to redesign our central store to have flattened
      // states so as to avoid this kind of deep-copying problems.
      let newState2 = {
        ...state,
        setlistHasChanged: true,
        currentPerformanceDetails: {
          ...state.currentPerformanceDetails,
          Setlist: {
            ...state.currentPerformanceDetails.Setlist
          }
        }
      };
      if (
        !newState2.currentPerformanceDetails.Setlist ||
        !newState2.currentPerformanceDetails.Setlist.Works
      ) {
        newState2.currentPerformanceDetails.Setlist = { Works: [] };
      } else {
        newState2.currentPerformanceDetails.Setlist.Works = [
          ...state.currentPerformanceDetails.Setlist.Works
        ];
      }
      newState2.currentPerformanceDetails.Setlist.Works.push(action.payload);
      return newState2;
    case REMOVE_WORK:
      const indexOfWorkToRemove = action.payload;
      const worksCopy = [...state.currentPerformanceDetails.Setlist.Works];
      worksCopy.splice(indexOfWorkToRemove, 1);
      return {
        ...state,
        setlistHasChanged: true,
        currentPerformanceDetails: {
          ...state.currentPerformanceDetails,
          Setlist: {
            Works: worksCopy
          }
        }
      };
    case CLEAR_SETLIST: {
      return {
        ...state,
        setlistHasBeenCleared: true,
        setlistHasChanged: true,
        currentPerformanceDetails: {
          ...state.currentPerformanceDetails,
          Setlist: {
            ...state.currentPerformanceDetails.Setlist,
            Works: []
          }
        }
      };
    }
    case UPDATE_PERFORMANCE_SUCCESS:
      return {
        ...state,
        currentPerformanceDetails: {
          ...state.currentPerformanceDetails,
          ...action.payload
        }
      };
    case UPDATE_SETLIST_WORK_DURATION:
      let newWorks = state.currentPerformanceDetails.Setlist.Works;
      newWorks[action.index].PerformanceDuration = action.duration;
      return {
        ...state,
        currentPerformanceDetails: {
          ...state.currentPerformanceDetails,
          Setlist: {
            ...state.currentPerformanceDetails.Setlist,
            Works: newWorks
          }
        }
      };
    default:
      return state;
  }
}
