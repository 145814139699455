export const required = value => (value ? undefined : "required");

export const mustBeNumber = value =>
  value ? (!isNaN(value) ? undefined : "Must be a number") : undefined;

export const mustBeInteger = value =>
  value
    ? Number.isInteger(Number(value))
      ? undefined
      : "Must be an integer"
    : undefined;

export const lengthIsBetween = (min, max) => value => {
  return value
    ? value.length >= min && value.length <= max
      ? undefined
      : `Number must be between ${min} and ${max} characters.`
    : undefined;
};

export const isMinutesSecondsFormat = value => {
  if (!/^\d\d?:\d{2}$/.test(value)) {
    return "Invalid. Must be mm:ss (e.g. 03:35)";
  }
  const [secs] = value.split(":").splice(1);
  if (Number(secs) > 59) {
    return "Seconds cannot be more than 59";
  }
  return undefined;
};

export const validateISWCChecksum = ISWC => {
  if (ISWC !== undefined) {
    const ISWCNumbersAsArray = ISWC.match(/\d/g).map(Number);

    const checkDigit = ISWCNumbersAsArray.pop();
    let sum = 1;
    for (let i in ISWCNumbersAsArray) {
      i = Number(i);
      const add = ISWCNumbersAsArray[i] * (i + 1);
      sum = sum + add;
    }
    const checkSum = (10 - (sum % 10)) % 10;
    return checkSum === checkDigit ? undefined : "Invalid ISWC";
  }
  return undefined;
};

export const validateISWCFormat = value => {
  const ISWCRegex = /^T(-)?\d{3}\.\d{3}\.\d{3}-\d|T\d{10}$/;
  return value
    ? ISWCRegex.test(value)
      ? undefined
      : "Invalid ISWC"
    : undefined;
};

export const validateIPIChecksum = input => {
  if (input !== undefined) {
    const checksum_for_validation = Number(input.slice(-2));
    const body = input
      .slice(0, input.length - 2)
      .split("")
      .reverse();
    let multiplyer = 2;
    let body_sum = 0;
    for (var number of body) {
      body_sum += number * multiplyer;
      multiplyer += 1;
    }
    const validatedChecksum = 101 - (body_sum % 101);
    return checksum_for_validation === validatedChecksum
      ? undefined
      : "IPI is invalid";
  }
  return null;
};

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const requiredAndIsMinutesSecondsFormat = composeValidators(
  required,
  isMinutesSecondsFormat
);

export const validateISWC = composeValidators(
  validateISWCFormat,
  validateISWCChecksum
);

export const validateIPI = composeValidators(
  mustBeInteger,
  lengthIsBetween(3, 10),
  validateIPIChecksum
);
