import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import openSnackbar from "redux/actions/openSnackbar";
import saveSetlist from "redux/actions/saveSetlist";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

const options = ["save as unconfirmed", "save and confirm"];

export const SaveChangesButton = ({
  onSaveAsDraft,
  onConfirmSetlist,
  onSaveEmptySetlist,
  performanceId,
  setlistStatus,
  works
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = (performanceId, status, works) => {
    if (selectedIndex === 0) {
      onSaveAsDraft(performanceId, "unconfirmed", works);
      return;
    }
    if (works.length === 0) {
      onSaveEmptySetlist();
      return;
    }
    onConfirmSetlist(performanceId, "confirmed", works);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="save changes button"
      >
        <Button
          onClick={e => handleClick(performanceId, setlistStatus, works)}
          data-cy="save-setlist-button"
        >
          {selectedIndex === 0 ? "Save as unconfirmed" : "Save and confirm"}
        </Button>
        <Button
          color="primary"
          size="small"
          aria-owns={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

SaveChangesButton.propTypes = {
  onSaveAsDraft: PropTypes.func,
  onConfirmSetlist: PropTypes.func
};

SaveChangesButton.defaultProps = {
  onSaveAsDraft: (x, y, z) => null,
  onConfirmSetlist: (x, y, z) => null,
  works: []
};

const mapStateToProps = state => {
  const performance = state.performanceDetailsReducer.currentPerformanceDetails;
  return {
    performanceId: performance.PerformanceId,
    works: performance && performance.Setlist && performance.Setlist.Works
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveEmptySetlist: () =>
    dispatch(openSnackbar("error", "You cannot confirm an empty setlist.")),
  onConfirmSetlist: (performanceId, status, works) =>
    dispatch(saveSetlist(performanceId, status, works)),
  onSaveAsDraft: (performanceId, status, works) =>
    dispatch(saveSetlist(performanceId, status, works))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveChangesButton);
