import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import NavigationBar from "components/navigation";
import PerformancesSubMenu from "components/PerformancesSubMenu";
import MainWrapper from "components/structural/MainWrapper";
import { PerformancesContainer } from "components/performances";
import UserService from "services/UserService";

const styles = {
  root: {
    background: "#D9DFE3"
  }
};

class PerformancesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: undefined,
      keywordFilter: undefined
    };

    this.setFilter = this.setFilter.bind(this);
  }

  async componentDidMount() {
    const user = await UserService.currentAuthenticatedUser();
    this.setState({ user: user });
  }

  setFilter(e) {
    this.setState({ filter: e.target.textContent });
  }

  setKeywordFilter = e => this.setState({ keywordFilter: e.target.value });

  render() {
    return (
      <div className={this.props.classes.root}>
        <Grid
          container
          style={{ background: "#EDEFF0", minHeight: "100vh" }}
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          <Grid item>
            <NavigationBar />
          </Grid>

          <Grid item>
            <PerformancesSubMenu
              variant={this.state.user && this.state.user.type}
              filter={this.state.filter}
              setFilter={this.setFilter}
              setKeywordFilter={this.setKeywordFilter}
            />
          </Grid>

          <MainWrapper>
            <PerformancesContainer
              key={this.props.location.search}
              activeFilter={this.state.filter}
              keywordFilter={this.state.keywordFilter}
              abroad={this.props.location.search.split("=")[1] === "abroad"}
            />
          </MainWrapper>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(PerformancesView));
