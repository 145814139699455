export const CMOR = "cmor";
export const PAR = "par";

export const COGNITO_CMORS_GROUP = "CMORs";
export const COGNITO_PARS_GROUP = "PARs";

export const UNKNOWN_STATUS = "unknown";
export const NEW_STATUS = "new";
export const SCHEDULED_STATUS = "scheduled";
export const INVOICED_STATUS = "invoiced";
export const COLLECTED_STATUS = "collected";
export const DISTRIBUTED_STATUS = "distributed";

export const PERFORMANCES_PATH = "/performances";

export const PERFORMANCE_STATUSES = [
  NEW_STATUS,
  SCHEDULED_STATUS,
  INVOICED_STATUS,
  COLLECTED_STATUS,
  DISTRIBUTED_STATUS
];
