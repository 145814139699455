import React from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import Grid from "@material-ui/core/Grid";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";

import Work from "components/works/Work";
import WorksSearchInput from "components/works/search/WorksSearchInput/ReduxWorksSearchInput";
import SaveChangesButton from "./SaveChangesButton";
import StatusBadge from "components/status/StatusBadge";

import { connect } from "react-redux";
import saveSetlist from "redux/actions/saveSetlist";
import {
  ADD_WORK,
  REMOVE_WORK,
  CLEAR_SETLIST,
  CLEAR_SEARCH_RESULTS,
  OPEN_DIALOG,
  ADD_USERGENERATED_WORK
} from "redux/actions/types";

const SEARCH_RESULT_WORK_DATA_ROLE = "work-instance-add";

export const AddWorkPrompt = ({ classes, onAddUserWork }) => (
  <Typography variant="body1">
    Looks like we don't know that one yet. Here's what you can do:
    <ul>
      <li>
        Please check the spelling of the title. Some musical works might appear
        under slightly different names.
      </li>
      <li>
        <span className={classes.addWorkButton} onClick={onAddUserWork}>
          <u>
            <strong>Add</strong>
          </u>
        </span>{" "}
        the song to the setlist manually.
      </li>
    </ul>
  </Typography>
);

const statusToVariant = status => {
  switch (status) {
    case "confirmed":
      return "green";
    case "unconfirmed":
      return "orange";
    default:
      return "gray";
  }
};

const statusToLabel = status =>
  ["confirmed", "unconfirmed"].includes(status) ? status : "unknown";

export const SetlistSection = ({
  classes,
  loading,
  performance,
  searchResults,
  setlistHasChanged,
  setlistHasBeenCleared,
  onAddWork,
  saveSetlist,
  onRemoveWork,
  onClearSetlist,
  onClearSearchInput,
  onAddUserWork,
  setlistStatus
}) => (
  <div className={classes.setlistSection}>
    <ReactPlaceholder showLoadingAnimation ready={!loading}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography gutterBottom variant="h6">
            Setlist
          </Typography>
        </Grid>
        <Grid item>
          <StatusBadge
            loading={loading}
            variant={statusToVariant(setlistStatus)}
            label={statusToLabel(setlistStatus)}
          />
        </Grid>
      </Grid>
    </ReactPlaceholder>
    <ReactPlaceholder showLoadingAnimation rows={3} ready={!loading}>
      {(!performance.Setlist ||
        !performance.Setlist.Works ||
        (performance.Setlist.Works.length === 0 && !setlistHasBeenCleared)) ===
      true ? (
        <NoSetlistInfo />
      ) : (
        <div>
          {performance.Setlist && performance.Setlist.Works && (
            <div data-role="works-list">
              {performance.Setlist.Works.map((work, i) => (
                <Work
                  key={i}
                  index={i}
                  data-cy={`setlist-work-${i}`}
                  data-role="work-instance-close"
                  title={work.Title}
                  iswc={work.ISWC}
                  duration={work.PerformanceDuration}
                  rightsOwners={work.RightsOwners.map(o =>
                    `${o.FirstName || ""} ${o.LastName || ""}`.trim()
                  )}
                  onClose={e => onRemoveWork(i)}
                />
              ))}
              <br />

              <Grid
                container
                spacing={3}
                alignItems="center"
                className={classes.actions}
              >
                <Grid item>
                  <SaveChangesButton
                    disabled={!setlistHasChanged}
                    style={{ marginRight: 20 }}
                    onSaveAsDraft={e =>
                      saveSetlist(
                        performance.PerformanceId,
                        performance.Setlist.Works
                      )
                    }
                  />
                </Grid>
                <Grid item>
                  <Button onClick={onClearSetlist} variant="contained">
                    Clear setlist
                  </Button>
                </Grid>
              </Grid>

              <Typography variant="body1" className={classes.margins}>
                If this setlist is incomplete or you would like to extend it,
                you can use the search box below to look for and add musical
                works.
              </Typography>
            </div>
          )}
        </div>
      )}
    </ReactPlaceholder>
    <br />

    <WorksSearchInput onClear={onClearSearchInput} />
    <br />
    {searchResults && searchResults.length === 0 && (
      <Grid container spacing={2} className={classes.warning}>
        <Grid item>
          <WarningOutlinedIcon />
        </Grid>
        <Grid item>
          <AddWorkPrompt classes={classes} onAddUserWork={onAddUserWork} />
        </Grid>
      </Grid>
    )}

    {searchResults &&
      searchResults.map((work, i) => (
        <Work
          key={i}
          data-role={SEARCH_RESULT_WORK_DATA_ROLE}
          title={work.Title}
          iswc={work.ISWC}
          duration={work.OriginalDuration}
          rightsOwners={
            (work.RightsOwners &&
              work.RightsOwners.map(o =>
                `${o.FirstName || ""} ${o.LastName || ""}`.trim()
              )) ||
            []
          }
          readOnly={true}
          onAdd={e => onAddWork(work)}
        />
      ))}
    <br />
  </div>
);

const NoSetlistInfo = props => (
  <Typography variant="body1">
    We don't currently have any setlist information for this performance. You
    can use the search box below to add musical works.
  </Typography>
);

SetlistSection.defaultProps = {
  classes: {},
  loading: false
};

const mapStateToProps = state => ({
  performance: state.performanceDetailsReducer.currentPerformanceDetails,
  searchResults: state.worksSearchReducer.searchResults,
  setlistHasChanged: state.performanceDetailsReducer.setlistHasChanged,
  setlistHasBeenCleared: state.performanceDetailsReducer.setlistHasBeenCleared,
  setlistStatus:
    state.performanceDetailsReducer.currentPerformanceDetails.Setlist &&
    state.performanceDetailsReducer.currentPerformanceDetails.Setlist.Status,
  loading: state.performanceDetailsReducer.isFetching
});

const mapDispatchToProps = dispatch => ({
  onAddUserWork: () =>
    dispatch({ type: OPEN_DIALOG, variant: ADD_USERGENERATED_WORK }),
  onAddWork: work => dispatch({ type: ADD_WORK, payload: work }),
  onRemoveWork: index => dispatch({ type: REMOVE_WORK, payload: index }),
  onClearSetlist: () => dispatch({ type: CLEAR_SETLIST }),
  onClearSearchInput: () => dispatch({ type: CLEAR_SEARCH_RESULTS }),
  saveSetlist: (performanceId, newWorks) =>
    dispatch(saveSetlist(performanceId, newWorks))
});

export default connect(mapStateToProps, mapDispatchToProps)(SetlistSection);
