import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Auth from "@aws-amplify/auth";

import NavigationBar from "./NavigationBar";
import NavigationLink from "./NavigationLink";
import { withRouter } from "react-router-dom";

const CMORNavigationBar = props => {
  const { classes, name, cmo, location } = props;
  const [currentPath, setCurrentPath] = useState(
    () => location.pathname + location.search
  );
  return (
    <NavigationBar>
      <NavigationLink
        active={currentPath === "/performances"}
        to="/performances"
        onClickHandler={path => setCurrentPath(path)}
      >
        Licensing
      </NavigationLink>
      <NavigationLink
        data-cy="go-to-abroad-view-link"
        active={currentPath === "/performances?v=abroad"}
        to="/performances?v=abroad"
        onClickHandler={path => setCurrentPath(path)}
      >
        Abroad
      </NavigationLink>
      {name && cmo && (
        <Button id="displayName" variant="contained" className={classes.button}>
          {name}/{cmo}
        </Button>
      )}

      <Button
        id="signOutButton"
        onClick={e => Auth.signOut()}
        variant="contained"
        className={classes.button}
      >
        Sign out
      </Button>
    </NavigationBar>
  );
};
const styles = {
  button: {
    color: "#fff",
    marginLeft: 20,
    borderRadius: 0,
    background: "#282850",
    "&:hover": {
      background: "#8006FF"
    }
  }
};

export default withRouter(withStyles(styles)(CMORNavigationBar));
