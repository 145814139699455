/**
 * This module exports a data loading component wrapping a presentational Performances container component.
 * For the most common use case (loading relevant live performances for the currently logged in user)
 * the exported component should be used directly, without any explicit props. If the way performances and the
 * currently logged in user are fetched needs to change, use the PresentationalPerformancesContainer wrapped in
 * a different data loading component that sets its `performances` and `userType` props.
 */
import React from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Logger } from "@aws-amplify/core";

import concertify from "concertify/core";
import UserService from "services/UserService";
import { CMOR, PAR, COGNITO_CMORS_GROUP } from "concertify/constants";
import {
  CMORPerformanceEntry,
  PARPerformanceEntry
} from "components/performances";
import { getCMOName } from "concertify/ArtistsAndCMOs";
import { titleCase, getPropertyOrUndefined } from "utils";
import Danger from "components/Danger";

const log = new Logger();

/**
 * DataPerformancesContainer handles the loading logic required to get the currently
 * authenticated user as well as his/her relevant Performances. It renders a `PresentationalPerformancesContainer`
 * with its `performances` and `userType` props set to these values.
 */
class DataPerformancesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: undefined,
      userCMOCode: undefined,
      performances: [],
      loading: false,
      error: undefined
    };
  }

  filterByStatus = () => {
    const status = this.props.activeFilter;
    log.debug("Filtering by status:", status);
    if (status && status !== "All") {
      return this.state.performances.filter(p => p.Status === status);
    }
    return this.state.performances;
  };

  filteredPerformances = () => {
    // hard-coded filter stuff
    // GMR should only see metallica performances
    // because of reasons beyond my understanding or
    // my scope of caring – Salvador, 15.04.2019
    const performances = this.filterByStatus();
    log.debug("filtered performances:", performances);
    if (this.state.userCMOCode === "778") {
      return performances.filter(p => p.PerformingArtist.Name === "metallica");
    }
    if (this.props.keywordFilter && this.props.keywordFilter !== "") {
      return performances.filter(p =>
        (p.PerformingArtist.Name || "")
          .toLowerCase()
          .includes(this.props.keywordFilter.toLowerCase())
      );
    }
    return performances;
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    try {
      const user = await UserService.currentAuthenticatedUser();
      const performances = await concertify.performances.getRelevantFor(
        user,
        this.props.abroad ? "abroad" : "domestic"
      );
      this.setState({
        userType: user.groups.includes(COGNITO_CMORS_GROUP) ? CMOR : PAR,
        userCMOCode: user.cmoCode,
        performances: performances,
        loading: false
      });
    } catch (e) {
      this.handleError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleError = e => {
    console.error(e);
    this.setState({
      error:
        "Something went wrong while trying to fetch your performances. Please try again later."
    });
  };

  render = () => (
    <PresentationalPerformancesContainer
      performances={this.filteredPerformances()}
      userType={this.state.userType}
      loading={this.state.loading}
      error={this.state.error}
    />
  );
}

/**
 * PresentationalPerformancesContainer renders either PARPerformanceEntry's or
 * CMORPerformanceEntry's from the `performances` prop, based on a `userType` prop.
 *
 * It should not be used by itself nor presented in the UI without a wrapping component that sets its `performances`
 * and `userType` props by loading data remotely. If either prop is not supplied it will render a 'loading' message.
 *
 * `performances` must be an array of Performance objects. `userType` must be one of `CMOR` or `PAR` from
 * `concertify/constants`.
 */
export const PresentationalPerformancesContainer = props => {
  const { performances, userType, loading, error } = props;
  if (loading) {
    return renderLoadingState();
  }
  if (error) {
    return renderErrorState(error);
  }
  if (!performances.length > 0) {
    return renderNoPerformancesState();
  }
  // otherwise, there are performances to render and for that we invariably need to know the userType, so ...
  if (userType === undefined) {
    throw new Error("InvariantViolation: userType prop cannot be undefined");
  }
  return (
    <div data-cy="performances-list">
      {userType === CMOR &&
        performances.map((performance, i) =>
          renderPerformanceForCMOR(performance, i)
        )}
      {/* TODO: implementation for the PAR performances is pending */}
      {userType === PAR &&
        performances.map((performance, i) =>
          renderPerformanceForPAR(performance, i)
        )}
    </div>
  );
};

const renderLoadingState = () => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", padding: 10 }}
      data-cy="performances-list"
    >
      <CircularProgress />
    </div>
  );
};

const renderNoPerformancesState = () => {
  return (
    <Typography data-role="no-relevant-performances" variant="body1">
      There are currently no performances we consider to be relevant to you.
    </Typography>
  );
};

const renderErrorState = e => <Danger>{e}</Danger>;

const renderPerformanceForCMOR = (performance, i) => {
  return (
    <CMORPerformanceEntry
      key={i}
      id={performance.PerformanceId}
      artist={titleCase(
        getPropertyOrUndefined(performance.PerformingArtist, "Name")
      )}
      status={performance.Status}
      date={performance.Date}
      recipients={["test"]}
      city={titleCase(
        getPropertyOrUndefined(performance.Venue.Address, "City")
      )}
      country={
        getPropertyOrUndefined(performance.Venue.Address, "CountryCode") !==
        undefined
          ? performance.Venue.Address &&
            performance.Venue.Address.CountryCode.toUpperCase()
          : "undefined"
      }
      venue={titleCase(performance.Venue.Name)}
      homeCmo={getCMOName(performance.CMOCode)}
      setlist={
        (performance.Setlist &&
          performance.Setlist.Works &&
          performance.Setlist.Works.length) ||
        0
      }
    />
  );
};

const renderPerformanceForPAR = (performance, i) => {
  console.log(performance);
  return <PARPerformanceEntry {...performance} />;
};

PresentationalPerformancesContainer.defaultProps = {
  performances: [],
  loading: false
};

PresentationalPerformancesContainer.propTypes = {
  performances: PropTypes.arrayOf(PropTypes.object),
  userType: PropTypes.oneOf([CMOR, PAR]),
  loading: PropTypes.bool
};

export default DataPerformancesContainer;
