import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: 750
  }
});

const TermsAndConditionsView = ({ classes }) => (
  <Grid container direction="column" alignItems="center" justify="center">
    <Grid data-role="terms-and-conditions" item className={classes.root}>
      <Typography variant="h5" gutterBottom>
        CONCERTIFY BETA – END-USER TERMS AND CONDITIONS
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        1. Scope and Background of Agreement
      </Typography>
      <Typography variant="body1" align="justify">
        Mind Your Rights Oy (Business ID 2863486-5) (“<strong>MYR</strong>”) is
        a Finnish technology start-up specializing in creating services for
        music rights management. MYR’s vision is to make global managing of
        music rights faster as well as more efficient and transparent for music
        authors and publishers and has developed a Software as a Service called
        “Concertify” for such purposes.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        These End-User Terms and Conditions (“<strong>Agreement</strong>” or “
        <strong>Terms</strong>”) govern the User’s use of Concertify Beta.
        Please read these Terms carefully. These Terms contain the terms and
        conditions between MYR and you regarding your access and use of
        Concertify Beta described below. Concertify Beta is available only to
        individuals who are acting in their capacity as an employee, member or
        representative of a User Organization (as described below).
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        By logging in Concertify Beta with your personal user credentials
        provided by MYR as described below, you will be bound by this Agreement
        and accept the terms of this Agreement in their entirety. You warrant
        that you have the required authority to register to and use Concertify
        Beta. Concertify Beta is not intended for consumer use. MYR and User are
        individually referred to as a “<strong>Party</strong>” and collectively
        the “<strong>Parties</strong>”.
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        2. Definitions
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        <strong>Concertify Beta</strong> – the beta version of the Standard
        Solution of Concertify provided by MYR.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        <strong>Confidential Information</strong> – any information and
        materials in any form that have been marked confidential or that are of
        a confidential and/or proprietary nature or that are to be reasonably
        considered trade secrets or otherwise confidential information of MYR or
        a third party (such as a customer of MYR), for example information
        concerning the business of MYR or a third party (such as a customer of
        MYR) as well as technical, commercial and financial information.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        <strong> Intellectual Property </strong>– any and all patents, utility
        models, design rights, copyrights (including the right to amend, modify,
        develop and assign), trademarks, trade names, inventions, trade secrets,
        domain names, designs, know-how, database rights and any other
        industrial or intellectual property rights (including applications
        thereof and both registered and unregistered rights).
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        <strong>Service Agreement</strong> – a service agreement concerning the
        use of Concertify Beta entered into between MYR and the User
        Organization you represent or another agreement concerning the use of
        Concertify Beta entered into between the User Organization you represent
        and another User Organization.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        <strong>User Organization</strong> – a collective management
        organization or another organization participating in the trial of
        Concertify Beta pursuant to a Service Agreement.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        <strong>You or User </strong>– an individual authorized to use
        Concertify Beta who is acting in his/her capacity as an employee, member
        or representative of a User Organization.
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        3. Use of Concertify Beta
      </Typography>
      <Typography variant="body1" align="justify">
        Subject to compliance with the terms agreed herein, MYR grants and User
        hereby accepts a non-exclusive, non-transferable, and non-sublicensable
        right to use the Concertify Beta during the term of this Agreement
        solely for the User Organization’s own business purposes and for
        evaluating Concertify Beta and not for the purposes of providing a
        service competing with Concertify Beta (save as set out in the Service
        Agreement, if applicable).
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        All rights not expressly granted to the User are reserved by MYR and its
        licensors. MYR reserves the right to make changes, modifications,
        reduction in functionality and enhancements to Concertify Beta, at any
        time, and from time to time without prior notice.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        You are responsible for preparing your hardware, connections, software
        and data systems to meet the operating environment of Concertify Beta.
        The use of Concertify Beta requires a functioning connectivity to
        internet.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        The functionalities of Concertify Beta become available to you through
        log-in. Your use of Concertify Beta is free of charge. You are entitled
        to receive customer support services with respect to Concertify Beta in
        accordance with the user guide provided to you by MYR by email and/or
        the Service Agreement, if applicable.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        MYR grants and creates you a user account with a username and a password
        with which you are able to log into Concertify Beta. The username and
        the password are sent to you by email.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        You shall use all reasonable endeavours to prevent unauthorized access
        to or use of your user account. In the event of or if you have reason to
        suspect any unauthorized access or use of your user account, or if your
        password has been revealed to a third party, you shall promptly notify
        MYR.
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        4. User Data
      </Typography>
      <Typography variant="body1" align="justify">
        MYR may collect and process your personal data, including personal data,
        in relation to your registration and use of Concertify Beta, such as
        user credentials and communication data (“<strong>User Data</strong>”).
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        MYR processes e.g. User’s user credentials as a data controller in
        accordance with its Privacy Policy in force from time to time and
        available on MYR’s website.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        MYR has the right to collect and generate anonymous data and statistics
        from the User Data and your use of Concertify Beta (“
        <strong>Aggregate Data</strong>”) for updates and development of
        Concertify Beta. The Intellectual Property Rights and title to Aggregate
        Data shall belong to MYR.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        MYR shall have the right, but shall not be obliged to, monitor the User
        Data in order to ensure compliance with the terms of this Agreement. In
        case MYR believes, in its reasonable opinion, that any User Data
        violates this Agreement, intellectual property rights or any applicable
        law, MYR shall have the right to delete such User Data from Concertify
        Beta.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        MYR disclaims any responsibility for the backup and/or retention of any
        User Data uploaded to Concertify Beta. Concertify Beta is not intended
        to be used as the sole storage location for User Data.
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        5. Feedback
      </Typography>
      <Typography variant="body1" align="justify">
        The User may evaluate and report to MYR its views on and input regarding
        Concertify Beta, including in regard to availability, performance and
        functionality thereof (“<strong>Feedback</strong>”).
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        MYR shall receive all ownership rights and Intellectual Property Rights
        in the Feedback (including all derivatives and improvements thereof).
        The User acknowledges that MYR may use the Feedback for the purposes of
        incorporating the Feedback into Concertify Beta (including all
        improvements thereof) and utilizing the Feedback when further developing
        Concertify Beta itself or through a third party. However, nothing in
        this Agreement shall constitute an obligation for MYR to use any
        Feedback provided by the User in any way.
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        6. Limitations on Use
      </Typography>
      <Typography variant="body1" align="justify">
        You may use Concertify Beta only and strictly in accordance with the
        terms of this Agreement and, where relevant, the Service Agreement.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        You may not:
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        <Typography variant="body1" gutterBottom>
          (i) license, sublicense, sell, transfer, assign, distribute or
          otherwise commercially exploit or make available to any third party
          Concertify Beta in any way;
        </Typography>
        <Typography variant="body1" gutterBottom>
          (ii) modify or make derivative works based upon Concertify Beta;
        </Typography>
        <Typography variant="body1" gutterBottom>
          (iii) reverse engineer Concertify Beta;
        </Typography>
        <Typography variant="body1" gutterBottom>
          (iv) access Concertify Beta in order to build a competitive product or
          service (save as set out in the Service Agreement, if applicable);
        </Typography>
        <Typography variant="body1" gutterBottom>
          (v) circumvent or attempt to circumvent any usage control or anti-copy
          features of Concertify Beta;
        </Typography>
        <Typography variant="body1" gutterBottom>
          (vi) probe, scan or test the vulnerability of Concertify Beta;
        </Typography>
        <Typography variant="body1" gutterBottom>
          (vii) use Concertify Beta or the content available through Concertify
          Beta in any manner that could damage, disable, overburden or impair
          Concertify Beta;
        </Typography>
        <Typography variant="body1" gutterBottom>
          (viii) use any data mining, robots, scraping, or similar data
          gathering or extraction methods;
        </Typography>
        <Typography variant="body1" gutterBottom>
          (ix) interfere with other users’ use and enjoyment of Concertify Beta;
        </Typography>
        <Typography variant="body1" gutterBottom>
          (x) use Concertify Beta for transmitting any unauthorized advertising,
          promotional materials, junk mail, spam, chain letters, contests,
          pyramid schemes, or any other form of solicitation or mass messaging;
        </Typography>
        <Typography variant="body1" gutterBottom>
          (xi) use Concertify Beta in ways that violate intellectual property
          rights, trade secrets or privacy of third parties;
        </Typography>
        <Typography variant="body1" gutterBottom>
          (xii) use Concertify Beta for unauthorized, inappropriate or unethical
          purposes or activities; or
        </Typography>
        <Typography variant="body1" gutterBottom>
          (xiii) use Concertify Beta to transmit any material that contains
          adware, malware, spyware, software viruses, worms or any other
          computer code designed to interrupt, destroy, or limit the
          functionality of computer software or equipment.
        </Typography>
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        7. Confidentiality
      </Typography>

      <Typography variant="body1" align="justify">
        User shall not disclose to third parties any Confidential Information
        received from MYR by any means and shall not use Confidential
        Information for any other purposes than those stated in this Agreement.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        The confidentiality obligation shall, however, not be applied to
        material and information, (a) which is generally available or otherwise
        public; or (b) which the User has received from a third party without
        any obligation of confidentiality; or (c) which was in the possession of
        the User prior to receipt of the same from MYR without any obligation of
        confidentiality related thereto; or (d) which the User has independently
        developed without using material or information received from MYR.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        The confidentiality obligation set out herein shall survive the
        termination of this Agreement.
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        8. Intellectual Property Rights
      </Typography>
      <Typography variant="body1" align="justify">
        MYR (and its licensors, where applicable) shall own all right, title and
        interest, including all related Intellectual Property Rights, in and to
        Concertify Beta and the Feedback, including to any and all enhancements,
        suggestions, modifications, extensions and/or derivative works thereof.
        This Agreement does not convey any rights of ownership in or related to
        Concertify Beta or Feedback to the User.
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        9. Availability
      </Typography>

      <Typography variant="body1" align="justify">
        MYR shall have the right to temporarily suspend the provision of
        Concertify Beta in accordance with the following, without any obligation
        to compensate any damages or service level failures to the User:
      </Typography>
      <br />

      <Typography variant="body1" align="justify">
        MYR shall have the right to suspend the availability of Concertify Beta
        for a reasonable duration due to installation, change or maintenance
        work of Concertify Beta or MYR’s network or due to severe data security
        risk to Concertify Beta or if required by law or public authorities. If
        MYR suspends Concertify Beta for this reason, it shall inform User of
        the suspension and the estimated duration thereof in advance or, if this
        is not reasonably possible, without undue delay after MYR has become
        aware of such occurrence.
      </Typography>
      <br />

      <Typography variant="body1" align="justify">
        MYR shall have the right to temporarily deny your access to Concertify
        Beta without any prior notice to you, if MYR suspects that you burden or
        use Concertify Beta in a manner which may jeopardize the availability of
        Concertify Beta to the other users.
      </Typography>
      <br />

      <Typography variant="body1" align="justify">
        You acknowledge that interruptions to the availability of Concertify
        Beta may also occur due to no fault of MYR, for example, in the event of
        data connection disruptions or interruptions to the availability of
        systems or components delivered by third parties.
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        10. Disclaimer and Warranties
      </Typography>
      <Typography variant="body1" align="justify">
        To the maximum extent permitted under applicable law, Concertify Beta,
        including any and all content thereof, is provided “as is” and “as
        available” and MYR hereby disclaims all express or implied
        representations, warranties and guarantees with regard to Concertify
        Beta and of merchantability, satisfactory quality, noninfringement and
        fitness for a particular purpose. MYR does not warrant that Concertify
        Beta, including any and all content thereof, is or will be error-free,
        will meet the User’s requirements, or be timely or secure. For clarity,
        this Section 10 applies to any and all data and information provided by
        third parties and included in Concertify Beta.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        Notwithstanding the above, MYR warrants that it has the right to enter
        into this Agreement and that the User has the right to use Concertify
        Beta in accordance with this Agreement.
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        11. Limitation of Liability
      </Typography>
      <Typography variant="body1" align="justify">
        To the maximum extent permitted under applicable law, neither Party (and
        its licensors) shall be liable to the other Party for any damages of
        whatever nature as a result of this Agreement or Concertify Beta,
        including but not limited to any direct, indirect or consequential
        damages arising out of or in any way connected with this Agreement or
        the use of or inability to use Concertify Beta.
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        12. Term and termination
      </Typography>

      <Typography variant="body1" align="justify">
        This Agreement shall enter into force immediately after you have logged
        in Concertify Beta with your user credentials for the first time. The
        Agreement shall stay in force as long as you are using Concertify Beta
        and, where relevant, provided that the Service Agreement is also in
        force.
      </Typography>
      <br />

      <Typography variant="body1" align="justify">
        MYR may, at any time, terminate this Agreement by deleting your user
        account with Concertify Beta.
      </Typography>
      <br />

      <Typography variant="body1" align="justify">
        The User may terminate this Agreement and request MYR to delete his/her
        user account with Concertify Beta at any time by sending MYR a written
        request. User’s user account will be deleted within one (1) month from
        the request.
      </Typography>
      <br />

      <Typography variant="body1" align="justify">
        Please note, that after termination of this Agreement for any reason,
        you shall immediately lose your access right to Concertify Beta. Further
        your user account and all User Data shall be deleted or anonymized and
        shall no longer be visible on Concertify Beta.
      </Typography>
      <br />

      <Typography variant="body1" align="justify">
        The provisions of this Agreement which by their nature reasonably should
        survive the termination or other expiration of this Agreement shall
        survive any expiration or termination.
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        13. Applicable law and dispute resolution
      </Typography>
      <Typography variant="body1" align="justify">
        The Agreement is governed by the laws of Finland, excluding its choice
        of law rules.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        Any dispute arising in connection with the Agreement shall be finally
        settled a) in accordance with the dispute resolution clause of the
        Service Agreement entered into between MYR and the User Organization you
        represent, if applicable, or b) by the district court of Helsinki if
        there is no valid Service Agreement between MYR and the User
        Organization represented by you.
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        14. Miscellaneous
      </Typography>

      <Typography variant="body1" align="justify">
        This Agreement and, where relevant, the Service Agreement set forth the
        entire Agreement and understanding among the Parties relative to the
        subject matter contained herein and supersedes all other agreements,
        oral and written, heretofore made between the Parties.
      </Typography>
      <br />

      <Typography variant="body1" align="justify">
        If one or more provisions of this Agreement is or becomes either in
        whole or in part illegal, invalid or unenforceable, this shall not
        affect the validity of the remaining provisions. The provision that is
        in whole or in part illegal, invalid or unenforceable shall be replaced
        by a valid provision that approximates as closely as possible the
        economic intent of the original provision.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        Where relevant, if there is any inconsistency between the provisions of
        this Agreement and the provisions of the Service Agreement entered into
        between MYR and the User Organization you represent, the provisions of
        such Service Agreement shall prevail.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        No waiver of any provision of this Agreement shall be deemed, or shall
        constitute, a waiver of any other provision, nor shall any waiver
        constitute a continuing waiver. No waiver shall be binding unless
        executed in writing by the Party making the waiver.
      </Typography>
      <br />

      <Typography variant="body1" align="justify">
        User may not assign this Agreement or any rights or obligations
        hereunder without the prior written consent of MYR. MYR has the right to
        assign this Agreement to its affiliates or successors as part of a
        restructuring, merger, acquisition, asset sale or other corporate
        reorganization.
      </Typography>
      <br />

      <Typography variant="body1" align="justify">
        MYR is entitled to amend this Agreement at any time by providing User
        with a notice thereof by email. If you do not accept the change made by
        MYR to this Agreement, please stop using the Service.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom>
        Privacy Statement
      </Typography>

      <Typography variant="body1" align="justify">
        Mind Your Rights Oy (“<strong>MYR</strong>” or “<strong>we</strong>”)
        processes personal data of the users of the music rights management
        Software as a Service provided by MYR ("<strong>Concertify Beta</strong>
        ") and the visitors of the website{" "}
        <a href="http://www.mindyourrights.fi/">mindyourrights.fi </a>
        (“<strong>Website</strong>”).
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        In this Privacy Statement, the word "<strong>Services</strong>” refers
        jointly to Concertify Beta and the Website. The word “
        <strong>User Organization</strong>” refers to the collective management
        organizations and other organizations registered to and using Concertify
        Beta. The word “<strong>User</strong>” or “<strong>you</strong>” refers
        jointly to the representatives or users of the User Organizations as
        well as the unregistered visitors of the Website.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        Our Privacy Statement explains,for example,the types of personal data we
        process, how we process the personal data and how you may use your
        rights as a data subject.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        Some of our services might be subject to a separate privacy policy. If a
        separate privacy policy applies to a particular service, we will post it
        in connection with the service in question.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        This Privacy Statement may be updated from time to time in order to
        reflect the changes in data processing practices or otherwise. You can
        find the current version on the Website. We will not make substantial
        changes to this Privacy Statement or reduce the rights of the Users
        under this Privacy Statement without providing a notice thereof to the
        Users who have provided us with their email address.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        This Privacy Statement applies to processing of personal data carried
        out by MYR as a data controller. For clarity we emphasize that this
        Privacy Statement does not address and is not applicable to the
        processing of personal data collected by the User Organizations or other
        third parties. We encourage you to familiarize yourself with the privacy
        policies of such third parties.
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        MYR's contact details
      </Typography>
      <Typography variant="body1" align="justify">
        Name: Mind Your Rights Oy
        <br />
        Company ID: 2863486-5
        <br />
        Correspondence address: Urho Kekkosen katu2 C, 00100 Helsinki
        <br />
        Phone number: (09) 681 011
        <br />
        E-mail address: info@mindyourrights.fi
        <br />
        <a href="https://www.mindyyourrights.fi">www.mindyourrights.fi</a>
        <br />
        Contact person: Roope Pajasmaa, roope.pajasmaa@mindyourrights.fi, +358
        50 3658351
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        Personal data processed and sources of data
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        The personal data collected and processed by us in connection with your
        registration and use of the Services can be divided into two general
        data categories: User Data and Analytics Data.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        <u>User Data</u>
      </Typography>
      <Typography variant="body1" align="justify">
        <br />
        User Data is personal data collected directly from Users or the Customer
        organizations they represent or generated by us. We may collect User
        Data from our Users and Customers in a variety of ways, including, by
        email, in connection with the User’s use of the Services and/or when a
        User subscribes to a newsletter.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        We may process the following User Data relating to the Users:
      </Typography>
      <Typography>
        <br />
        • name
        <br />
        • email
        <br />
        • phone number
        <br />
        • password
        <br />
        • marketing opt-ins and opt-outs
        <br />
        • payment details
        <br />
        • the organization the User represents
        <br />
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        <u>Analytics Data</u>
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        The logging systems used by the Services automatically log certain
        Analytics Data when you visit the Services. Although we do not normally
        use Analytics Data to identify you as an individual, you can sometimes
        be recognized from it, either alone or when combined or linked with User
        Data. In such situations, Analytics Data can also be considered personal
        data under applicable laws and we will treat such data as personal data.
      </Typography>
      <Typography variant="body1" align="justify">
        We may automatically collect the following Analytics Data when you visit
        or interact with the Services:
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        • <strong>Device Information.</strong> We collect the following
        information relating to the technical device you use when using the
        Services:
        <br />
        <br />
        o device and device identification number, device IMEI <br />
        o country <br />
        o IP address <br />
        o browser type and version <br />
        o operating system <br />
        o name of your Internet service providers <br />
        o advertising identifier of your device <br />
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        • <strong>Usage Information.</strong> We collect information on your use
        of the Services, such as: <br />
        <br />
        o time spent in the Services <br />o interaction with the Services o the
        time and date of your visits to the Services o the sections of the
        Services you visited
      </Typography>
      <br />
      <Section
        title="Cookies"
        titleStyle="underlined"
        paragraphs={[
          "We use various technologies to collect and store Analytics Data and other information when the Users visit the Services, including cookies, pixel tags and web beacons.",
          "Cookies are small text files sent and saved on your device that allows us to identify visitors of the Services and facilitate the use of the Services and to create aggregate information of our visitors. This helps us to improve the Services and better serve our Users. The cookies will not harm your device or files. We use cookies to tailor the Services and the information we provide in accordance with the individual interests of our Users.",
          "The Users may choose to set their web browser to refuse cookies, or to alert when cookies are being sent. For example, the following links provide information on how to adjust the cookie settings on some popular browsers:"
        ]}
      />
      <Typography variant="body1" align="justify">
        <a href="https://support.apple.com/kb/PH19214?viewlocale=en_US&locale=en_US">
          Safari
        </a>
      </Typography>
      <Typography variant="body1" align="justify">
        <a href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies">
          Google Chrome
        </a>
      </Typography>
      <Typography variant="body1" align="justify">
        <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11">
          Internet Explorer
        </a>
      </Typography>
      <Typography variant="body1" align="justify">
        <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">
          Mozilla Firefox
        </a>
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        Please note that some parts of the Services may not function properly if
        use of cookies is refused.
      </Typography>
      <br />
      <Section
        title="Web analytics services"
        titleStyle="underline"
        paragraphs={[
          "The Website uses Google Analytics and other web analytics services to compile Analytics Data and reports on visitor usage and to help us improve the Website. For an overview of Google Analytics, please visit Google Analytics. It is possible to opt-out of Google Analytics with the following browser add-on tool: Google Analytics opt-out add-on.",
          <strong>
            Purposes and legitimate grounds for the processing of your personal
            data.
          </strong>
        ]}
      />
      <Section
        title="Purposes"
        titleStyle="underline"
        paragraphs={[
          "There are several purposes of the processing of your personal data by MYR:"
        ]}
      />
      <Section
        title="To provide the Services"
        titleStyle="italics"
        paragraphs={[
          "MYR processes your personal data to be able to offer the Services to you and to your Customer organization.",
          "If you contact our customer service, we will use the information provided by you to answer your questions or solve your complaint."
        ]}
      />
      <Section
        title="For our legal obligations"
        titleStyle="italics"
        paragraphs={[
          "MYR processes data to enable us to administer and fulfil our obligations under the law. This includes data processed for complying with our bookkeeping obligations and providing information to relevant authorities such as tax authorities."
        ]}
      />
      <Section
        title="For claims handling and legal processes"
        titleStyle="italics"
        paragraphs={[
          "MYR may process personal data in relation to claims handling, debt collection and legal processes. We may also process data for the prevention of fraud, misuse of our services and for data, system and network security."
        ]}
      />
      <Section
        title="For customer communication and marketing"
        titleStyle="italics"
        paragraphs={[
          "MYR processes your personal data to contact you regarding the Services and to inform you of changes relating to it. Your personal data are also used for the purposes of marketing the Services and our other relevant services to you."
        ]}
      />
      <Section
        title="For quality improvement and trend analysis"
        titleStyle="italics"
        paragraphs={[
          "We may also process information about your use of the Services to improve the quality of the Services e.g. by analyzing any trends in the use of the Services. In order to ensure that our services are in line with your needs, personal data can be used for things like customer satisfaction surveys. When possible, we will do this using only aggregated, non-personally identifiable data."
        ]}
      />
      <Section
        title="Legal grounds for processing"
        titleStyle="underline"
        paragraphs={[
          "MYR processes your personal data primarily to pursue our legitimate interest to run, maintain and develop our business and to create and maintain customer and other business relationships. We also process your personal data for our legitimate interest whilst fulfilling our contractual obligations towards our Customers. When choosing to use your data on the basis of our legitimate interests, we weigh our own interests against your right to privacy and e.g. provide you with easy to use opt-out from our marketing communications and use pseudonymized or non-personally identifiable data when possible.",
          "We may also process your personal data in order to comply with legal obligations.",
          "In some parts of the Services, you may be requested to grant your consent for the processing of personal data. In this event, you may withdraw your consent at any time."
        ]}
      />
      <Section
        title="Transfer to countries outside Europe"
        titleStyle="bold"
        paragraphs={[
          "MYR stores your personal data primarily within the European Economic Area. However, we have service providers in several geographical locations. As such, we and our service providers may transfer your personal data to, or access it in, jurisdictions outside the European Economic Area.",
          "We will take steps to ensure that the Users’ personal data receives an adequate level of protection in the jurisdictions in which they are processed. We provide adequate protection for the transfers of personal data to countries outside of the European Economic Area through a series of agreements with our service providers based on the Standard Contractual Clauses or through other appropriate safeguards, such as the Privacy Shield Framework.",
          "More information regarding the transfers of personal data may be obtained by contacting us on any of the addresses indicated above."
        ]}
      />
      <Section
        title="Recipients"
        titleStyle="bold"
        paragraphs={[
          "We only share your personal data within our organization if and as far as reasonably necessary for the purposes of this Privacy Statement.",
          "We do not share your personal data with third parties outside of MYR’s organization unless one of the following circumstances applies:",
          "For the purposes set out in this Privacy Statement and to authorized service providers",
          "To the extent that third parties (such as the Customers) need access to User Data in order for us to perform the Services, we provide such third parties with your data. Please note, that the Customers process such User Data as independent controllers for their own purposes.",
          "Furthermore, we may provide your personal data to our affiliates or to authorized service providers who perform services for us (including data storage, accounting, sales and marketing) to process it for us and to payment service providers to process your payments to us.",
          "When your personal data is processed by third parties as data processors on behalf of MYR, MYR has taken the appropriate contractual and organizational measures to ensure that your data are processed exclusively for the purposes specified in this Privacy Statement and in accordance with all applicable laws and regulations and subject to our instructions and appropriate obligations of confidentiality and security measures.",
          "Please bear in mind that if you provide personal data directly to a third party, such as through a link in the Services, the processing is typically based on their policies and standards."
        ]}
      />
      <Section
        title="For legal reasons and legal processes"
        titleStyle="italics"
        paragraphs={[
          "We may share your personal data with third parties outside our organization if we have a good-faith belief that access to and use of the personal data is reasonably necessary to: (i) meet any applicable law, regulation, and/or court order; (ii) detect, prevent, or otherwise address fraud, crime, security or technical issues; and/or (iii) protect the interests, properties or safety of MYR, the Users or the public as far as in accordance with the law. When possible, we will inform you about such processing."
        ]}
      />
      <Section
        title="For other legitimate reasons"
        titleStyle="italics"
        paragraphs={[
          "If MYR is involved in a merger, acquisition or asset sale, we may transfer your personal data to the third party involved. However, we will continue to ensure the confidentiality of all personal data. We will give notice to all the Users concerned when the personal data are transferred or become subject to a different privacy statement."
        ]}
      />
      <Section
        title="With your explicit consent"
        titleStyle="italics"
        paragraphs={[
          "We may share your personal data with third parties outside MYR when we have your explicit consent to do so. You have the right to withdraw this consent at all times."
        ]}
      />
      <Section
        title="Storage period"
        titleStyle="bold"
        paragraphs={[
          "MYR does not store your personal data longer than is legally permitted and necessary for the purposes of this Privacy Statement. The storage period depends on the nature of the information and on the purposes of processing. The maximum period may therefore vary per use.",
          "Most User Data will be deleted after a period of 3 months has lapsed after your Customer organization has terminated their registration to the Services. Thereafter, some of the User Data may be stored by us only as long as such processing is required by law or is reasonably necessary for our legal obligations or legitimate interests such as claims handling, bookkeeping, internal reporting and reconciliation purposes.",
          "We will store Analytics Data for a period of 14 months."
        ]}
      />
      <Section title="Your rights" titleStyle="bold" />
      <Section
        title="Right to access"
        titleStyle="italics"
        paragraphs={[
          "You have the right to access and be informed about your personal data processed by us. We give you the possibility to request a copy of your personal data."
        ]}
      />
      <Section
        title="Right to withdraw consent"
        titleStyle="italics"
        paragraphs={[
          "In case the processing is based on a consent granted by the User, the User may withdraw the consent at any time. Withdrawing a consent may lead to fewer possibilities to use the Services. The withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal."
        ]}
      />
      <Section
        title="Right to rectify"
        titleStyle="italics"
        paragraphs={[
          "You have the right to have incorrect or incomplete personal data we have stored about you corrected or completed by contacting us."
        ]}
      />
      <Section
        title="Right to erasure"
        titleStyle="italics"
        paragraphs={[
          "You may also ask us to delete your personal data from our systems. We will comply with such request unless we have a legitimate ground to not delete the data."
        ]}
      />
      <Section
        title="Right to object"
        titleStyle="italics"
        paragraphs={[
          "You may have the right to object to certain use of your personal data if such data are processed for other purposes than necessary for the provision of the Services or for compliance with a legal obligation. If you object to the further processing of your personal data, this may lead to fewer possibilities to use the Services."
        ]}
      />
      <Section
        title="Right to restriction of processing"
        titleStyle="italics"
        paragraphs={[
          "You may request us to restrict processing of personal data for example when your data erasure, rectification or objection requests are pending and/or when we do not have legitimate grounds to process your data. This may however lead to fewer possibilities to use the Services."
        ]}
      />
      <Section
        title="Right to data portability"
        titleStyle="italics"
        paragraphs={[
          "You have the right to receive the personal data you have provided to us yourself in a structured and commonly used format and to independently transmit those data to a third party."
        ]}
      />
      <Section
        title="How to use your rights"
        titleStyle="italics"
        paragraphs={[
          "The abovementioned rights may be used by sending a letter or an e-mail to us on the addresses set out above, including the following information: the full name, e-mail address and a phone number. We may request the provision of additional information necessary to confirm the identity of the User. We may reject requests that are unreasonably repetitive, excessive or manifestly unfounded."
        ]}
      />
      <Section
        title="Direct marketing"
        titleStyle="bold"
        paragraphs={[
          "The User has the right to prohibit us from using the User’s personal data for direct marketing purposes, market research and profiling made for direct marketing purposes by contacting us on the addresses indicated above or by using the functionalities of the Services or the unsubscribe possibility offered in connection with any direct marketing messages."
        ]}
      />
      <Section
        title="Lodging a complaint"
        titleStyle="bold"
        paragraphs={[
          "In case the User considers our processing of personal data to be inconsistent with the applicable data protection laws, a complaint may be lodged with the local supervisory authority for data protection. In Finland, the local supervisory authority is the Data Protection Ombudsman (www.tietosuoja.fi)."
        ]}
      />
      <Section
        title="Information security"
        titleStyle="bold"
        paragraphs={[
          "We use administrative, organizational, technical, and physical safeguards to protect the personal data we collect and process. Measures include for example, where appropriate, encryption, pseudonymization, firewalls, secure facilities and access right systems. Our security controls are designed to maintain an appropriate level of data confidentiality, integrity, availability, resilience and ability to restore the data. We regularly test the Services, systems, and other assets for security vulnerabilities.",
          "Should despite of the security measures, a security breach occur that is likely to have negative effects to the privacy of the Users, we will inform the relevant Users and other affected parties, as well as relevant authorities when required by applicable data protection laws, about the breach as soon as possible."
        ]}
      />
    </Grid>
  </Grid>
);

const Section = ({ title, paragraphs = [], titleStyle }) => (
  <div>
    <Typography>
      {(titleStyle === "underline" && <u>{title}</u>) ||
        (titleStyle === "italics" && <i>{title}</i>) ||
        (titleStyle === "bold" && <b>{title}</b>) ||
        title}
    </Typography>
    <br />
    {paragraphs.map(p => (
      <div>
        <Typography variant="body1" align="justify">
          {p}
        </Typography>
        <br />
      </div>
    ))}
  </div>
);

export default withStyles(styles)(TermsAndConditionsView);
