import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";

import NavigationBar from "components/navigation";
import MainWrapper from "./MainWrapper";

const styles = {
  root: {
    height: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    background: "#D9DFE3",
    lineHeight: "1.2em"
  },
  subNav: {
    minHeight: 30,
    background: "#373762"
  }
};

const MainBody = withStyles(styles)(
  ({ classes, navLinks, subNavComponent, children, hideNav, hideSubNav }) => (
    <div className={classes.root}>
      {!hideNav && <NavigationBar>{navLinks}</NavigationBar>}
      {!hideSubNav && (
        <AppBar position="static" className={classes.subNav}>
          {subNavComponent}
        </AppBar>
      )}

      <MainWrapper>{children}</MainWrapper>
    </div>
  )
);

MainBody.defaultProps = {
  hideNav: false,
  hideSubNav: false
};

MainBody.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.object,
  navLinks: PropTypes.object,
  subNavComponent: PropTypes.object,
  hideNav: PropTypes.bool,
  hideSubNav: PropTypes.bool
};

export default MainBody;
