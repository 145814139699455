import Auth from "@aws-amplify/auth";
import { CURRENT_AUTH_USER } from "./types";

export const currentAuthenticatedUser = () => async dispatch => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    dispatch({
      type: CURRENT_AUTH_USER,
      payload: {
        ...user.attributes,
        cmo: user.attributes["custom:homeCmo"],
        cmoCode: user.attributes["custom:cmoCode"],
        type: user.attributes["custom:type"]
      }
    });
  } catch (e) {
    throw new Error(e);
  }
};
