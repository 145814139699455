import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { Link } from "react-router-dom";

const styles = theme => ({
  button: {
    [theme.breakpoints.up("sm")]: {
      color: "#fff",
      padding: "10px 0px",
      opacity: 0.7,
      borderRadius: 0,
      marginLeft: 20,
      "&:hover": {
        opacity: 1
      }
    },
    [theme.breakpoints.down("xs")]: {}
  },
  outlined: {
    border: "none",
    borderRadius: 0
  },
  active: {
    opacity: 1
  },
  icon: {
    paddingRight: 10,
    paddingTop: 10
  }
});

const NavigationLink = ({
  classes,
  children,
  active,
  icon,
  to,
  onClickHandler,
  ...rest
}) => {
  let classNames = [classes.button];
  if (active) {
    classNames.push(classes.active);
  }
  return (
    <Button
      component={Link}
      to={to}
      className={classNames.join(" ")}
      onClick={() => onClickHandler(to)}
      disableRipple
      {...rest}
    >
      <div className={classes.icon}>{icon}</div> {children}
    </Button>
  );
};

NavigationLink.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.string.isRequired,
  active: PropTypes.bool,
  icon: PropTypes.object,
  to: PropTypes.string
};

NavigationLink.defaultProps = {
  active: false,
  to: "#"
};

export default withStyles(styles)(NavigationLink);
