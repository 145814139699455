import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";

import { connect } from "react-redux";

import closeDialog from "redux/actions/closeDialog";
import { simpleDate, formatMoney } from "utils";
import { updatePerformance } from "redux/actions/performanceActions";
import { composeValidators } from "components/forms/formValidation";
import { required } from "components/forms/formValidation";
import { mustBeNumber } from "components/forms/formValidation";
import DateField from "components/forms/DateField";
import CurrencyField from "components/forms/CurrencyField";
import MoneyField from "components/forms/MoneyField";
import TextInput from "components/forms/TextInput";

import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import EditIcon from "@material-ui/icons/Edit";

const EditableTableRow = ({
  value,
  label,
  dataRole,
  readOnly,
  Input,
  InputProps,
  formatValue = v => v
}) => {
  const [isEditing, setEditing] = useState(false);

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      return null;
    }
  };

  return (
    <Grid container alignItems="center" style={{ minHeight: 35 }}>
      <Grid item xs={6}>
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      </Grid>

      <Grid container item xs={6} alignItems="center">
        <Grid item xs={11}>
          {(!isEditing && (
            <Typography data-role={dataRole}>
              {value === undefined ? "N/A" : value}
            </Typography>
          )) || (
            <Input
              autoFocus
              style={{ marginTop: 0 }}
              onKeyPress={handleKeyPress}
              {...InputProps}
            />
          )}
        </Grid>

        {!readOnly && (
          <Grid item xs={1} style={{ textAlign: "right" }}>
            <IconButton
              color="primary"
              onClick={e => setEditing(!isEditing)}
              size="small"
              data-role={`${dataRole}-edit-button`}
            >
              {(!isEditing && <EditIcon fontSize="small" />) || (
                <CloseIcon fontSize="small" />
              )}
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const PercentageInput = ({ placeholder, ...props }) => (
  <Field placeholder={placeholder || "25%"} {...props} component={TextField} />
);

export const LicenseDialog = ({
  onSubmit,
  open,
  onClose,
  initialValues,
  performanceId,
  EstimatedValue,
  EstimatedValueDate,
  currency,
  BoxOffice,
  SocietyTariffValue,
  SocietyTariffPercentage,
  SocietyAdminFeePercentage,
  SocietyAdminFeeValue,
  LicenseFee,
  Collections,
  OtherDeductions,
  DeductionsDesc,
  NetDistributable,
  PaymentDate,
  artistName,
  performanceDate,
  readOnly
}) => {
  return (
    <Dialog open={open} fullWidth data-role="license-dialog">
      <DialogTitle>
        <Grid container alignItems="center" justify="space-between">
          Licensing Breakdown
          <IconButton onClick={onClose} data-role="license-close-button">
            <CloseIcon />
          </IconButton>
        </Grid>
        {artistName && performanceDate && (
          <Typography variant="subtitle2" color="textSecondary">
            {`${artistName}, ${simpleDate(performanceDate)}`}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <Form onSubmit={onSubmit || (e => null)} initialValues={initialValues}>
          {({ handleSubmit, values, pristine, invalid, ...rest }) => (
            <form>
              <Typography variant="subtitle2">
                Pre-Licensing Estimate
              </Typography>
              <Divider />

              <br />

              <EditableTableRow
                dataRole="license-currency"
                readOnly={readOnly}
                label="Currency*"
                Input={CurrencyField}
                value={values.Currency}
                InputProps={{
                  name: "Currency",
                  validate: required,
                  fullWidth: true
                }}
              />

              <EditableTableRow
                dataRole="license-estimated-value"
                readOnly={readOnly}
                label="Estimated Net Distributable*"
                value={formatMoney(values.EstimatedValue, values.Currency)}
                Input={MoneyField}
                InputProps={{
                  name: "EstimatedValue",
                  validate: composeValidators(required, mustBeNumber),
                  "data-role": "license-estimated-value"
                }}
              />

              <EditableTableRow
                dataRole="license-estimated-date"
                readOnly={readOnly}
                label="Estimated Dist. Date*"
                Input={DateField}
                value={simpleDate(values.EstimatedValueDate)}
                formatValue={dateStr => simpleDate(dateStr)}
                InputProps={{
                  name: "EstimatedValueDate",
                  validate: required,
                  variant: "inline",
                  PickerProps: {
                    variant: "inline",
                    format: "do MMMM yyyy",
                    disableToolbar: true,
                    "data-role": "license-estimated-date"
                  },
                  KeyboardButtonProps: {
                    "data-role": "license-estimated-date-calendar-button"
                  }
                }}
              />

              <br />

              <Typography variant="subtitle2">
                Actual Figures And Deductions
              </Typography>
              <Divider />

              <EditableTableRow
                dataRole="license-box-office"
                readOnly={readOnly}
                label="Box Office"
                value={formatMoney(values.BoxOffice, values.Currency)}
                Input={MoneyField}
                InputProps={{
                  name: "BoxOffice",
                  validate: mustBeNumber
                }}
              />

              <EditableTableRow
                dataRole="license-tariff-rate"
                readOnly={readOnly}
                label="Tariff Rate"
                value={
                  values.SocietyTariffPercentage
                    ? `${values.SocietyTariffPercentage}%`
                    : undefined
                }
                Input={PercentageInput}
                InputProps={{
                  name: "SocietyTariffPercentage",
                  validate: mustBeNumber
                }}
              />

              <EditableTableRow
                dataRole="license-tariff-value"
                readOnly={readOnly}
                label="Tariff"
                value={formatMoney(values.SocietyTariffValue, values.Currency)}
                Input={MoneyField}
                InputProps={{
                  name: "SocietyTariffValue",
                  validate: mustBeNumber
                }}
              />

              <EditableTableRow
                dataRole="license-admin-rate"
                readOnly={readOnly}
                label="Admin Rate"
                value={
                  values.SocietyAdminFeePercentage
                    ? `${values.SocietyAdminFeePercentage}%`
                    : undefined
                }
                Input={PercentageInput}
                InputProps={{
                  name: "SocietyAdminFeePercentage",
                  validate: mustBeNumber
                }}
              />

              <EditableTableRow
                dataRole="license-admin-value"
                readOnly={readOnly}
                label="Admin Fee"
                value={formatMoney(
                  values.SocietyAdminFeeValue,
                  values.Currency
                )}
                Input={MoneyField}
                InputProps={{
                  name: "SocietyAdminFeeValue",
                  validate: mustBeNumber
                }}
              />

              <EditableTableRow
                dataRole="license-collections"
                readOnly={readOnly}
                label="Collections (after admin.)"
                value={formatMoney(values.Collections, values.Currency)}
                Input={MoneyField}
                InputProps={{
                  name: "Collections",
                  validate: mustBeNumber
                }}
              />

              <EditableTableRow
                dataRole="license-other-deductions"
                readOnly={readOnly}
                label="Other Deductions"
                value={formatMoney(values.OtherDeductions, values.Currency)}
                Input={MoneyField}
                InputProps={{
                  name: "OtherDeductions",
                  validate: mustBeNumber
                }}
              />

              <EditableTableRow
                dataRole="license-other-deductions-description"
                readOnly={readOnly}
                label="Deductions Description"
                value={values.DeductionsDesc}
                Input={TextInput}
                InputProps={{
                  name: "DeductionsDesc",
                  multiline: true,
                  rows: 2,
                  fullWidth: true,
                  placeholder:
                    "Cultural deduction: 156.54\nValue Added Tax: 324.98"
                }}
              />

              <EditableTableRow
                dataRole="license-net-distributable"
                readOnly={readOnly}
                label={
                  <b>
                    <u>Net Distributable</u>
                  </b>
                }
                value={formatMoney(values.NetDistributable, values.Currency)}
                Input={MoneyField}
                InputProps={{
                  name: "NetDistributable",
                  validate: mustBeNumber
                }}
              />

              <EditableTableRow
                dataRole="license-payment-transfer-date"
                readOnly={readOnly}
                label="Payment Transfer Date"
                value={simpleDate(values.PaymentDate)}
                Input={DateField}
                format={v => new Date(v)}
                InputProps={{
                  name: "PaymentDate",
                  PickerProps: {
                    variant: "inline",
                    format: "do MMMM yyyy",
                    disableToolbar: true,
                    "data-role": "license-payment-transfer-date"
                  },
                  KeyboardButtonProps: {
                    "data-role": "license-payment-transfer-date-calendar-button"
                  }
                }}
              />

              <br />

              {!readOnly && (
                <Button
                  onClick={e => {
                    onSubmit(performanceId, { License: values });
                  }}
                  color="primary"
                  variant="contained"
                  disabled={invalid}
                  fullWidth
                  data-role="license-submit-button"
                >
                  save changes
                </Button>
              )}
            </form>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};

LicenseDialog.defaultProps = {
  open: false
};

const mapStateToProps = state => {
  const performance = state.performanceDetailsReducer.currentPerformanceDetails;
  return {
    performanceId: performance.PerformanceId,
    initialValues: {
      Currency: performance.License && performance.License.Currency,
      EstimatedValue: performance.License && performance.License.EstimatedValue,
      EstimatedValueDate:
        performance.License && performance.License.EstimatedValueDate,
      BoxOffice: performance.License && performance.License.BoxOffice,
      LicenseFee: performance.License && performance.License.LicenseFee,
      SocietyTariffPercentage:
        performance.License && performance.License.SocietyTariffPercentage,
      SocietyTariffValue:
        performance.License && performance.License.SocietyTariffValue,
      SocietyAdminFeePercentage:
        performance.License && performance.License.SocietyAdminFeePercentage,
      SocietyAdminFeeValue:
        performance.License && performance.License.SocietyAdminFeeValue,
      Collections: performance.License && performance.License.Collections,
      OtherDeductions:
        performance.License && performance.License.OtherDeductions,
      DeductionsDesc: performance.License && performance.License.DeductionsDesc,
      NetDistributable:
        performance.License && performance.License.NetDistributable,
      PaymentDate: performance.License && performance.License.PaymentDate
    },
    artistName:
      performance.PerformingArtist && performance.PerformingArtist.Name,
    performanceDate: performance.Date,
    open:
      state.dialogReducer.isOpen === true &&
      state.dialogReducer.variant === "LicenseDetails"
  };
};

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeDialog()),
  onSubmit: (performanceId, payload) => {
    dispatch(updatePerformance(performanceId, payload));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicenseDialog);
