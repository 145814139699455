import { OPEN_DIALOG } from "redux/actions/types";

export default variant => {
  if (
    ["DeletePerformance", "AddUsergeneratedWork", "LicenseDetails"].includes(
      variant
    )
  ) {
    return {
      type: OPEN_DIALOG,
      variant: variant
    };
  }
  return {
    type: OPEN_DIALOG,
    variant: ""
  };
};
