//TODO: [MYR-602] Replace hard-coded CMOS in source code with calls to api.concertify.io/v1/cmos (web client)
export const CMOs = {
  "001": {
    Name: "ACUM",
    CountryCode: "IL"
  },
  "005": {
    Name: "AKM",
    CountryCode: "AT"
  },
  "008": {
    Name: "APRA",
    LongName: "Australasian Performing Right Association",
    CountryCode: "AU"
  },
  "010": {
    Name: "ASCAP",
    LongName: "American Society of Composers, Authors and Publishers",
    CountryCode: "US"
  },
  "035": {
    Name: "GEMA",
    LongName:
      "Gesellschaft für musikalische Aufführungs-und mechanische Vervielfältigungsrechte",
    CountryCode: "DE"
  },
  "040": {
    Name: "KODA",
    LongName: "KODA",
    CountryCode: "DK"
  },
  "050": {
    Name: "OSA",
    CountryCode: "CZ"
  },
  "052": {
    Name: "PRS",
    LongName: "Performing Right Society",
    CountryCode: "UK"
  },
  "055": {
    Name: "SABAM",
    LongName: "Société d'Auteurs Belge – Belgische Auteurs Maatschappij",
    CountryCode: "BE"
  },
  "072": {
    Name: "SGAE",
    CountryCode: "ES"
  },
  "079": {
    Name: "STIM",
    LongName: "Svenska Tonsättares Internationella Musikbyrå",
    CountryCode: "SE"
  },
  "080": {
    Name: "SUISA",
    CountryCode: "CH"
  },
  "089": {
    Name: "Teosto",
    LongName: "Säveltäjäin Tekijänoikeustoimisto Teosto",
    CountryCode: "FI"
  },
  "090": {
    Name: "TONO",
    LongName: "TONO",
    CountryCode: "NO"
  },
  "094": {
    Name: "RAO",
    LongName: "Russian Authors Society",
    CountryCode: "RU"
  },
  "097": {
    Name: "ZAIKS",
    CountryCode: "PL"
  },
  "106": {
    Name: "COMPASS",
    LongName: "Composers and Authors Society of Singapore",
    CountryCode: "SG"
  },
  "115": {
    Name: "UCMR-ADA",
    CountryCode: "RO"
  },
  "116": {
    Name: "EAU",
    CountryCode: "EE"
  },
  "128": {
    Name: "IMRO",
    LongName: "Irish Music Rights Organisation",
    CountryCode: "IE"
  },
  "778": {
    Name: "GMR",
    LongName: "Global Music Rights",
    CountryCode: "US"
  },
  "101": {
    Name: "SOCAN",
    LongName: "Society of Composers, Authors and Music Publishers of Canada",
    CountryCode: "CA"
  },
  "023": {
    Name: "BUMA",
    LongName: "BUMA",
    CountryCode: "NL"
  }
};

export const getCMOCountryCode = cmoCode => {
  if (CMOs.hasOwnProperty(cmoCode)) {
    return CMOs[cmoCode].CountryCode.toLowerCase();
  } else {
    throw new Error(`No such CMO ${cmoCode}`);
  }
};

export const getCMOName = cmoCode =>
  CMOs.hasOwnProperty(cmoCode) ? CMOs[cmoCode].Name : undefined;

export const getCMOByCountryCode = countryCode => {
  for (let cmoCode in CMOs) {
    if (CMOs[cmoCode].CountryCode.toLowerCase() === countryCode.toLowerCase()) {
      return CMOs[cmoCode];
    }
  }
  throw new Error(`No CMO with country code ${countryCode}`);
};

export const isLicensingCMOR = (cmorUser, performance) =>
  getCMOCountryCode(cmorUser.cmoCode) ===
  performance.Venue.Address.CountryCode.toLowerCase();
