import React from "react";
import { connect } from "react-redux";

import CMORNavigationBar from "./CMORNavigationBar";
import CMORDrawer from "./CMORDrawer";

const CMORNavigation = props => {
  const { name, cmo, drawerOpen } = props;
  return (
    <div>
      <CMORNavigationBar name={name} cmo={cmo} />
      <CMORDrawer open={drawerOpen} name={name} cmo={cmo} />
    </div>
  );
};

const mapStateToProps = state => ({
  drawerOpen: state.navReducer.navigation.drawerOpen
});

export default connect(
  mapStateToProps,
  null
)(CMORNavigation);
