import React, { useState } from "react";

import { connect } from "react-redux";
import deletePerformance from "redux/actions/deletePerformance";

import { makeStyles } from "@material-ui/styles";
import red from "@material-ui/core/colors/red";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import { Form, Field } from "react-final-form";
import { Select } from "final-form-material-ui";

const useStyles = makeStyles(theme => ({
  danger: {
    color: red[500]
  }
}));

const DeletePerformanceForm = ({
  onDelete,
  onCancel,
  deleter,
  performanceId
}) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const reasons = [
    { code: "Cancelled", message: "The performance was cancelled" },
    { code: "WrongSubmission", message: "Wrong submission" }
  ];
  return (
    <Form
      // TODO: [MYR-684] check was is the right way to pass down onSubmit to whatever
      // is being rendered by the render prop
      onSubmit={() => null}
      deleter={deleter}
      performanceId={performanceId}
      initialValues={{ reason: reasons[0].code }}
      render={({ handleSubmit, pristine, invalid, values, ...rest }) => (
        <div>
          <Field
            name="reason"
            formControlProps={{ fullWidth: true }}
            component={Select}
          >
            {reasons.map(reason => (
              <MenuItem key={reason.code} value={reason.code}>
                {reason.message}
              </MenuItem>
            ))}
          </Field>
          {isLoading && <CircularProgress size={15} />}
          <Grid container alignItems="center" justify="flex-end">
            <Button
              onClick={async () => {
                setLoading(true);
                await onDelete(deleter, performanceId, values.reason);
                setLoading(false);
              }}
              className={classes.danger}
            >
              Delete performance
            </Button>
          </Grid>
        </div>
      )}
    />
  );
};

const mapStateToProps = state => {
  return {
    performanceId:
      state.performanceDetailsReducer.currentPerformanceDetails.PerformanceId
  };
};

const mapDispatchToProps = dispatch => ({
  onDelete: (deleter, performanceId, reasonForDeletion) =>
    dispatch(deletePerformance(deleter, performanceId, reasonForDeletion))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeletePerformanceForm);
