import React, { useState } from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

const WorksSearchInput = props => {
  const { classes, searching, onSearch, onClear } = props;
  const [currentQuery, setQuery] = useState("");
  return (
    <TextField
      id="workSearchInput"
      fullWidth
      value={currentQuery}
      label="Search for musical works"
      helperText={"Input a musical work's title and press enter."}
      variant="outlined"
      margin="normal"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            {searching ? (
              <CircularProgress size={25} style={{ marginRight: 15 }} />
            ) : (
              <IconButton
                onClick={e => {
                  setQuery("");
                  if (onClear) {
                    onClear();
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </InputAdornment>
        )
      }}
      onChange={e => {
        setQuery(e.target.value);
        if (e.target.value === "" && onClear) {
          onClear();
        }
      }}
      onKeyPress={e =>
        e.key === "Enter" ? onSearch(currentQuery.toLowerCase()) : null
      }
      className={classes.worksInput}
    />
  );
};

WorksSearchInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onSearch: PropTypes.func
};

WorksSearchInput.defaultProps = {
  classes: {},
  onSearch: () => null
};

export default WorksSearchInput;
