import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import purple from "@material-ui/core/colors/purple";
import { withStyles } from "@material-ui/core/styles";
import Auth from "@aws-amplify/auth";
import { connect } from "react-redux";

import { TOGGLE_DRAWER_ACTION } from "actions/toggleDrawerAction";

const BaseNavigationDrawer = props => {
  const { classes, children, name, cmo, onClose, ...rest } = props;
  return (
    <Drawer {...rest}>
      <div className={classes.topBorder} />
      <Header classes={classes} name={name} cmo={cmo} onClose={onClose} />
      <Divider />
      <List>
        {loadingOr(children)}
        <Divider />
        <SignOutButton />
      </List>
    </Drawer>
  );
};

const styles = theme => ({
  topBorder: {
    minHeight: 10,
    background: purple[900]
  },
  headerRoot: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between"
  },
  headerSubtext: {
    opacity: 0.5
  }
});

export const NavigationDrawer = withStyles(styles)(BaseNavigationDrawer);

const mapDispatchToProps = dispatch => ({
  onClose: e => dispatch({ type: TOGGLE_DRAWER_ACTION })
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(NavigationDrawer));

// -------------------------------------------------

const Header = props => {
  const { classes, name, cmo, onClose } = props;
  return (
    <div className={classes.headerRoot}>
      <div>
        <Typography variant="h6">{loadingOr(name, 250)}</Typography>
        <Typography variant="body1" className={classes.headerSubtext}>
          {loadingOr(cmo, 150)}
        </Typography>
      </div>
      <IconButton onClick={onClose}>
        <ChevronLeftIcon />
      </IconButton>
    </div>
  );
};

const SignOutButton = props => {
  return (
    <ListItem button onClick={e => Auth.signOut()}>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Sign out" />
    </ListItem>
  );
};

const loadingOr = (value, width) => {
  if (!value) {
    return (
      <div
        style={{
          width: width,
          minHeight: 20,
          background: "#dbdbdb",
          marginBottom: 10
        }}
      />
    );
  }
  return value;
};
