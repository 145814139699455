import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { formatMoney } from "utils";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ReactPlaceHolder from "react-placeholder";
import ReactPlaceholder from "react-placeholder";

import { CMOR } from "concertify/constants";
import { simpleDate } from "utils";
import openDialog from "redux/actions/openDialog";

const useStyles = makeStyles(theme => ({
  root: {
    background: "#F4F5F8",
    padding: theme.spacing(2),
    border: "1px solid #e2e2f4"
  }
}));

export const EventValueBox = ({
  estimatedValue,
  estimatedValueDate,
  boxOfficeValue,
  netDistributable,
  paymentTransferDate,
  currency,
  onShowDetails,
  loading,
  variant
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root} id="event-value-box">
      <Typography variant="subtitle1">Pre-licensing Estimate</Typography>
      <Divider />
      <Grid
        container
        justify="space-betweem"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={4}>
          <Typography variant="overline" color="textSecondary">
            Distributable
          </Typography>
          <ReactPlaceholder showLoadingAnimation ready={!loading}>
            <Typography
              variant="body2"
              gutterBottom
              data-role="estimatedValueField"
            >
              {`${
                estimatedValue === undefined
                  ? "N/A"
                  : formatMoney(estimatedValue, currency)
              } ${currency ? currency : ""}`.trim()}
            </Typography>
          </ReactPlaceholder>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="overline" color="textSecondary">
            distr. date
          </Typography>
          <ReactPlaceHolder showLoadingAnimation ready={!loading}>
            <Typography
              variant="body2"
              gutterBottom
              data-role="estimatedValueDateField"
            >
              {estimatedValueDate ? simpleDate(estimatedValueDate) : "N/A"}
            </Typography>
          </ReactPlaceHolder>
        </Grid>
      </Grid>
      <br />
      <Typography variant="subtitle1">Actual Figures</Typography>
      <Divider />
      <Grid
        container
        justify="space-betweem"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={4}>
          <Typography variant="overline" color="textSecondary">
            Box Office
          </Typography>
          <ReactPlaceHolder showLoadingAnimation ready={!loading}>
            <Typography
              variant="body2"
              gutterBottom
              data-role="boxOfficeValueField"
            >
              {boxOfficeValue === undefined
                ? "N/A"
                : formatMoney(boxOfficeValue, currency)}
            </Typography>
          </ReactPlaceHolder>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="overline" color="textSecondary">
            Distributable
          </Typography>
          <ReactPlaceHolder showLoadingAnimation ready={!loading}>
            <Typography
              variant="body2"
              gutterBottom
              data-role="netDistributableField"
            >
              {netDistributable === undefined
                ? "N/A"
                : formatMoney(netDistributable, currency)}
            </Typography>
          </ReactPlaceHolder>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="overline" color="textSecondary">
            distr. date
          </Typography>
          <ReactPlaceHolder showLoadingAnimation ready={!loading}>
            <Typography
              variant="body2"
              gutterBottom
              data-role="paymentTransferDateField"
            >
              {paymentTransferDate ? simpleDate(paymentTransferDate) : "N/A"}
            </Typography>
          </ReactPlaceHolder>
        </Grid>
      </Grid>
      <br />
      {variant === CMOR && (
        <Button
          size="small"
          color="primary"
          onClick={onShowDetails}
          data-role="event-value-box-show-details-button"
        >
          show details
        </Button>
      )}
    </div>
  );
};

EventValueBox.propTypes = {
  estimatedValue: PropTypes.number,
  estimatedValueDate: PropTypes.string,
  currency: PropTypes.string,
  boxOfficeValue: PropTypes.number,
  netDistributable: PropTypes.number,
  paymentTransferDate: PropTypes.string,
  onShowDetails: PropTypes.func,
  loading: PropTypes.bool,
  variant: CMOR
};

const mapStateToProps = state => {
  const performance = state.performanceDetailsReducer.currentPerformanceDetails;
  return {
    estimatedValue: performance.License && performance.License.EstimatedValue,
    estimatedValueDate:
      performance.License && performance.License.EstimatedValueDate,
    currency: performance.License && performance.License.Currency,
    boxOfficeValue: performance.License && performance.License.BoxOffice,
    netDistributable:
      performance.License && performance.License.NetDistributable,
    paymentTransferDate: performance.License && performance.License.PaymentDate,
    loading: state.performanceDetailsReducer.isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  onShowDetails: () => {
    dispatch(openDialog("LicenseDetails"));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventValueBox);
