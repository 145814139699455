import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { titleCase } from "utils";
import {
  NEW_STATUS,
  SCHEDULED_STATUS,
  INVOICED_STATUS,
  COLLECTED_STATUS,
  DISTRIBUTED_STATUS,
  PERFORMANCE_STATUSES
} from "concertify/constants";

let stepFor = {};
stepFor[NEW_STATUS] = 0;
stepFor[SCHEDULED_STATUS] = 1;
stepFor[INVOICED_STATUS] = 2;
stepFor[COLLECTED_STATUS] = 3;
stepFor[DISTRIBUTED_STATUS] = 5; // 5 instead of 4 so that the stepper shows the step as 'completed'

export const PerformanceStatusStepper = props => {
  const { status } = props;
  return (
    <Stepper
      id="PerformanceStatusStepper"
      alternativeLabel
      activeStep={stepFor[status]}
      style={{ padding: "50px 10px 40px 10px", background: "#e3e7e9" }}
    >
      {PERFORMANCE_STATUSES.map(status => (
        <Step>
          <StepLabel>{titleCase(status)}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

PerformanceStatusStepper.propTypes = {
  status: PropTypes.oneOf(PERFORMANCE_STATUSES)
};

PerformanceStatusStepper.defaultProps = {
  status: NEW_STATUS
};

const mapStateToProps = state => ({
  status: state.performanceDetailsReducer.currentPerformanceDetails.Status
});

export default connect(
  mapStateToProps,
  null
)(PerformanceStatusStepper);
