import React from "react";

import UserService from "services/UserService";
import { CMOR, PAR } from "concertify/constants";
import { getCMOName } from "concertify/ArtistsAndCMOs";
import CMORNavigation from "./CMORNavigation";
import PARNavigation from "./PARNavigation";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Logger } from "@aws-amplify/core";

class MainNavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.logger = new Logger("MainNavigationBar");
    this.state = {
      loading: true
    };
  }
  async componentDidMount() {
    this.setState({
      loading: true
    });
    try {
      const user = await UserService.currentAuthenticatedUser();
      this.setState({
        authenticatedUser: user
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    if (
      this.state.authenticatedUser &&
      this.state.authenticatedUser.type === PAR
    ) {
      return (
        <PARNavigation
          name={this.state.authenticatedUser.name}
          cmo={getCMOName(this.state.authenticatedUser.cmoCode)}
        />
      );
    }
    if (
      this.state.authenticatedUser &&
      this.state.authenticatedUser.type === CMOR
    ) {
      return (
        <CMORNavigation
          name={
            this.state.authenticatedUser && this.state.authenticatedUser.name
          }
          cmo={
            this.state.authenticatedUser &&
            getCMOName(this.state.authenticatedUser.cmoCode)
          }
        />
      );
    }
    return <CircularProgress />;
  }
}

export default MainNavigationBar;
