import React from "react";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import HomeIcon from "@material-ui/icons/Home";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Link } from "react-router-dom";

import NavigationDrawer from "./NavigationDrawer";
import DrawerLink from "./DrawerLink";

const CMORDrawer = props => {
  const { name, cmo, open } = props;
  return (
    <NavigationDrawer name={name} cmo={cmo} open={open}>
      <ListItem>
        <Typography variant="h6">Live Performances</Typography>
      </ListItem>
      <Link to="/performances">
        <DrawerLink icon={<HomeIcon />} primary="Licensing" />
      </Link>
      <Link to="/performances?v=abroad">
        <DrawerLink icon={<LocationOnIcon />} primary="Abroad" />
      </Link>
    </NavigationDrawer>
  );
};

export default CMORDrawer;
