import React from "react";
import { connect } from "react-redux";

import PARNavigationBar from "./PARNavigationBar";
import PARDrawer from "./PARDrawer";

const PARNavigation = props => {
  const { name, cmo, drawerOpen } = props;
  return (
    <div>
      <PARNavigationBar name={name} cmo={cmo} />
      <PARDrawer open={drawerOpen} name={name} cmo={cmo} />
    </div>
  );
};

const mapStateToProps = state => ({
  drawerOpen: state.navReducer.navigation.drawerOpen
});

export default connect(
  mapStateToProps,
  null
)(PARNavigation);
