export default {
  en: {
    "Cannot read property 'username' of undefined": "Username cannot be empty",
    "Sign In": "Login",
    "Sign in to your account": "Login to Concertify",
    "User does not exist": "Incorrect username and/or password",
    "null failed with error Generate callenges lambda cannot be called..":
      "Incorrect username and/or password",
    "null invocation failed due to configuration.":
      "Incorrect username and/or password",
    "Custom auth lambda trigger is not configured for the user pool.":
      "Incorrect username and/or password"
  }
};
