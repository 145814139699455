import React, { useState } from "react";

import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Form, Field } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Select } from "final-form-material-ui";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import { countries } from "utils";
import { tryOrUndefined } from "utils";
import updatePerformance from "performances/updatePerformance/updatePerformanceReduxAction";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
    height: 35
  },
  marginRight: {
    marginRight: theme.spacing(1)
  }
}));

const EditableFieldBase = ({
  label,
  editingMode = false,
  listComponent,
  fieldComponent,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Grid item xs={5}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.marginRight}
        >
          {`${label}:`}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        {(!editingMode && listComponent) || fieldComponent}
      </Grid>
    </Grid>
  );
};

export const EditableTextField = ({ value, name, placeholder, ...rest }) => (
  <EditableFieldBase
    listComponent={
      <Typography variant="body1">{value || "Not available"}</Typography>
    }
    fieldComponent={
      <Field component={TextField} name={name} placeholder={placeholder} />
    }
    {...rest}
  />
);

export const EditableCountryField = ({
  value: countryCode,
  name,
  placeholder,
  ...rest
}) => (
  <EditableFieldBase
    listComponent={
      <Typography variant="body1">
        {countries[countryCode] || "Not available"}
      </Typography>
    }
    fieldComponent={
      <Field native component={Select} name={name} placeholder={placeholder}>
        {Object.entries(countries).map(entry => (
          <option value={entry[0]}>{entry[1]}</option>
        ))}
      </Field>
    }
    {...rest}
  >
    {countries[countryCode]}
  </EditableFieldBase>
);

export const EditableAddressField = props => (
  <EditableTextField placeholder="Boulevard st. 159" {...props} />
);

export const EditablePhoneField = props => (
  <EditableTextField placeholder="+4912345678970" {...props} />
);

export const EditableWebsiteField = props => (
  <EditableTextField placeholder="https://myvenue.com" {...props} />
);

export const EditablePostalCodeField = props => (
  <EditableTextField placeholder="81825" {...props} />
);

export const EditableFieldGroup = ({
  children,
  onSubmit,
  editButtonLabel,
  idPrefix,
  ...rest
}) => {
  const [isEditing, setIsEditing] = useState(false);
  let initialValues = {};
  React.Children.forEach(children, child => {
    initialValues[child.props.name] = child.props.value;
  });
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, submitting, values }) => (
        <form onSubmit={handleSubmit} {...rest}>
          {React.Children.map(children, EditableField =>
            React.cloneElement(EditableField, { editingMode: isEditing })
          )}
          <br />
          <Grid container justify="space-between">
            {!isEditing && (
              <Fade in={!isEditing}>
                <Button
                  id={`${idPrefix}EditButton`}
                  fullWidth
                  size="small"
                  variant="contained"
                  style={{ boxShadow: "none" }}
                  color={!isEditing ? "default" : "primary"}
                  onClick={e => setIsEditing(true)}
                >
                  Edit
                </Button>
              </Fade>
            )}
            {isEditing && (
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={6}>
                  <Fade in={isEditing}>
                    <Button
                      id={`${idPrefix}SaveButton`}
                      fullWidth
                      size="small"
                      variant="contained"
                      style={{ boxShadow: "none" }}
                      color="primary"
                      type="submit"
                    >
                      Save changes
                    </Button>
                  </Fade>
                </Grid>
                <Grid item xs={6}>
                  <Fade in={isEditing}>
                    <Button
                      id={`${idPrefix}CancelButton`}
                      fullWidth
                      variant="contained"
                      size="small"
                      style={{ boxShadow: "none" }}
                      onClick={e => setIsEditing(false)}
                    >
                      Cancel
                    </Button>
                  </Fade>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      )}
    />
  );
};

export const EditableVenueInfo = connect(
  state => ({
    performanceId:
      state.performanceDetailsReducer.currentPerformanceDetails.PerformanceId,
    venue: state.performanceDetailsReducer.currentPerformanceDetails.Venue
  }),
  dispatch => ({
    onSubmit: (values, form, callback) => {
      const { performanceId } = values;
      const updatedAttrs = {
        Venue: {
          Name: values["venueName"],
          Address: {
            City: values["venueCity"],
            CountryCode: values["venueCountryCode"].toUpperCase(),
            Line1: values["venueLine1"],
            Line2: values["venueLine2"],
            Zipcode: values["venueZipCode"],
            Phone: values["venuePhone"],
            Website: values["venueWebsite"]
          }
        }
      };
      dispatch(updatePerformance(performanceId, updatedAttrs));
    }
  })
)(({ performanceId, venue, onSubmit, ...rest }) => (
  <EditableFieldGroup onSubmit={onSubmit} {...rest} idPrefix="venueInfo">
    <input type="hidden" name="performanceId" value={performanceId} />
    <EditableTextField
      name="venueName"
      label="Name"
      value={tryOrUndefined(venue, v => venue.Name)}
    />
    <EditableTextField
      name="venueCity"
      label="City"
      value={tryOrUndefined(venue, v => venue.Address.City)}
    />
    <EditableCountryField
      name="venueCountryCode"
      label="Country"
      value={tryOrUndefined(venue, v =>
        venue.Address.CountryCode.toLowerCase()
      )}
    />
    <EditableAddressField
      label="Address 1"
      name="venueLine1"
      value={tryOrUndefined(venue, v => venue.Address.Line1)}
    />
    <EditableAddressField
      label="Address 2"
      name="venueLine2"
      value={tryOrUndefined(venue, v => venue.Address.Line2)}
    />
    <EditablePostalCodeField
      label="Postal Code"
      name="venueZipCode"
      value={tryOrUndefined(venue, v => venue.Address.ZipCode)}
    />
    <EditablePhoneField
      label="Phone"
      name="venuePhone"
      value={tryOrUndefined(venue, v => venue.Address.Phone)}
    />
    <EditableWebsiteField
      label="Website"
      name="venueWebsite"
      value={tryOrUndefined(venue, v => venue.Address.Website)}
    />
  </EditableFieldGroup>
));

export const EditableOrganizerInfo = connect(
  state => ({
    performanceId:
      state.performanceDetailsReducer.currentPerformanceDetails.PerformanceId,
    organizer:
      state.performanceDetailsReducer.currentPerformanceDetails.Organizer
  }),
  dispatch => ({
    onSubmit: (values, form, callback) => {
      const { performanceId } = values;
      const updatedAttrs = {
        Organizer: {
          Name: values["organizerName"],
          Address: {
            City: values["organizerCity"],
            CountryCode: tryOrUndefined(values["organizerCountryCode"], cc =>
              cc.toUpperCase()
            ),
            Line1: values["organizerLine1"],
            Line2: values["organizerLine2"],
            Zipcode: values["organizerZipCode"],
            Phone: values["organizerPhone"],
            Website: values["organizerWebsite"]
          }
        }
      };
      dispatch(updatePerformance(performanceId, updatedAttrs));
    }
  })
)(({ performanceId, organizer, onSubmit, ...rest }) => (
  <EditableFieldGroup onSubmit={onSubmit} {...rest} idPrefix="organizerInfo">
    <input type="hidden" name="performanceId" value={performanceId} />
    <EditableTextField
      placeholder="Hard Rock Ltd."
      name="organizerName"
      label="Name"
      value={tryOrUndefined(organizer, v => organizer.Name)}
    />
    <EditableTextField
      name="organizerCity"
      placeholder="Los Angeles"
      label="City"
      value={tryOrUndefined(organizer, v => organizer.Address.City)}
    />
    <EditableCountryField
      name="organizerCountryCode"
      label="Country"
      value={tryOrUndefined(organizer, v =>
        organizer.Address.CountryCode.toLowerCase()
      )}
    />
    <EditableAddressField
      label="Address 1"
      name="organizerLine1"
      value={tryOrUndefined(organizer, v => organizer.Address.Line1)}
    />
    <EditableAddressField
      label="Address 2"
      name="organizerLine2"
      value={tryOrUndefined(organizer, v => organizer.Address.Line2)}
    />
    <EditablePostalCodeField
      label="Postal Code"
      name="organizerZipCode"
      value={tryOrUndefined(organizer, v => organizer.Address.Zipcode)}
    />
    <EditablePhoneField
      label="Phone"
      name="organizerPhone"
      value={tryOrUndefined(organizer, v => organizer.Address.Phone)}
    />
    <EditableWebsiteField
      label="Website"
      name="organizerWebsite"
      value={tryOrUndefined(organizer, v => organizer.Address.Website)}
    />
  </EditableFieldGroup>
));
