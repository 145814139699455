import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import purple from "@material-ui/core/colors/purple";
import { withStyles } from "@material-ui/core/styles";

const DrawerLink = props => {
  const { classes, active, icon, primary, secondary } = props;
  return (
    <ListItem button className={active ? classes.active : ""}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  );
};

const styles = {
  active: {
    background: purple[100]
  }
};

export default withStyles(styles)(DrawerLink);
