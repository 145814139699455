import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Logo from "components/Logo";
import { TOGGLE_DRAWER_ACTION } from "actions/toggleDrawerAction";

export class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuAnchorEl: null,
      menuOpen: false
    };
  }

  render() {
    const { classes, children, onMenuOpen } = this.props;
    return (
      <AppBar position="static" classes={{ root: classes.root }}>
        <div className={classes.center}>
          <div className={classes.logoWrapper}>
            <Logo style={{ width: 155 }} size="meow" />
          </div>

          <ShowInLargeScreens>{children}</ShowInLargeScreens>

          <ShowInSmallScreens>
            <IconButton onClick={onMenuOpen}>
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </ShowInSmallScreens>
        </div>
      </AppBar>
    );
  }
}

const styles = {
  root: {
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#181843",
    padding: "0px 10px",
    minHeight: 60
  },
  center: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    maxWidth: 1240
  },
  logoWrapper: {
    flex: 1
  },
  brand: {
    maxWidth: 15
  },
  logo: {
    width: "155px",
    marginTop: "5px"
  },
  menuIcon: {
    color: "#fff"
  },
  dropdown: {
    position: "absolute",
    top: 50
  },
  dropdownPaper: {
    background: "#272850"
  },
  menuItem: {
    color: "#fff"
  }
};

const mapDispatchToProps = dispatch => ({
  onMenuOpen: e => dispatch({ type: TOGGLE_DRAWER_ACTION })
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(NavigationBar));

const ShowInLargeScreens = ({ children }) => <Hidden xsDown>{children}</Hidden>;
const ShowInSmallScreens = ({ children }) => <Hidden smUp>{children}</Hidden>;
