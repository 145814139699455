import React from "react";

import { connect } from "react-redux";
import { CLOSE_DIALOG, DELETE_PERFORMANCE } from "redux/actions/types";
import ReduxDeletePerformanceForm from "components/forms/DeletePerformanceForm";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import green from "@material-ui/core/colors/green";

const useStyles = makeStyles(theme => ({
  success: {
    color: green[500]
  },
  modal: {
    minWidth: 1500
  }
}));

export const DeletePerformanceConfirmationDialog = ({
  open,
  onClose,
  deleter
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.modal}>
      <DialogTitle>
        <Grid container alignItems="center" justify="space-between">
          Delete performance?
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          This performance will no longer be visible on Concertify. If you are
          sure you want to proceed, please choose one of the following reasons
          for deleting the performance:
        </DialogContentText>
        <ReduxDeletePerformanceForm deleter={deleter} />
      </DialogContent>
    </Dialog>
  );
};
DeletePerformanceConfirmationDialog.defaultProps = {
  open: false
};

// Redux version

const mapStateToProps = state => {
  return {
    open:
      state.dialogReducer.isOpen === true &&
      state.dialogReducer.variant === DELETE_PERFORMANCE
  };
};

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch({ type: CLOSE_DIALOG })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeletePerformanceConfirmationDialog);
