import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import Amplify, { I18n } from "@aws-amplify/core";
import config from "./config";
import { Provider } from "react-redux";
import configureStore from "store";
import vocabularies from "vocabularies";
import theme from "components/ConcertifyMuiTheme";
import { MuiThemeProvider } from "@material-ui/core/styles";

const cognito = config.cognito[config.app.env];
// Amplify refers to Cognito as Auth, S3 as Storage, and API Gateway as API:
Amplify.configure({
  Auth: {
    mandatorySignIn: true, // sign-in is required to interact with the app
    region: cognito.region,
    userPoolId: cognito.userPoolId,
    identityPoolId: cognito.identityPoolId,
    userPoolWebClientId: cognito.clientId
  },
  Analytics: {
    disabled: true
  }
  // Storage: {
  // 	region: config.s3.region,
  // 	bucket: config.s3.bucket,
  // 	identityPoolId: config.cognito.identityPoolId
  // },
  // API: {  // Amplify allows multiple APIs the app works with
  // 	endpoints: [{
  // 		name: "performances",
  // 		endpoint: config.apiGateway.url,
  // 		region: config.apiGateway.region
  // 	},]
  // }
});

Amplify.Logger.LOG_LEVEL = config.app.env === "dev" ? "DEBUG" : "INFO";

I18n.putVocabularies(vocabularies);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={configureStore}>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
