import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2)
  },
  a: {
    color: "#fff",
    textDecoration: "none",
    fontSize: 12
  }
});

const TermsAndConditionsFooter = ({ classes }) => (
  <div className={classes.root}>
    <a href="/terms-and-conditions" target="_blank" className={classes.a}>
      Concertify Beta Version {process.env.REACT_APP_VERSION} - End User Terms
      and Conditions
    </a>
  </div>
);

export default withStyles(styles)(TermsAndConditionsFooter);
